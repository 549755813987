import { FC } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import {
  BaseTextFieldProps,
  experimentalStyled,
  FormControl,
  TextField,
} from "@mui/material";

const TextFieldStyled = experimentalStyled(TextField)(() => ({
  "&[readonly] > div.MuiInputBase-root": {
    backgroundColor: "#f4f4f4",
  },
}));

interface InputFieldProps extends BaseTextFieldProps {
  name: string;
  label: string;
  helperText?: any;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  readOnly?: boolean;
  customOnBlur?: any;
  customOnFocus?: any;
}

const InputField: FC<InputFieldProps> = (props) => {
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "small",
    fullWidth = true,
    variant = "outlined",
    required = false,
    readOnly = false,
    customOnBlur,
  } = props;
  const { handleBlur } = useFormikContext();
  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl fullWidth={fullWidth}>
          <TextFieldStyled
            {...props}
            {...field}
            autoComplete="off"
            name={name}
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            required={required}
            placeholder={placeholder}
            helperText={helperText}
            label={label}
            InputProps={{ readOnly }}
            onBlur={(event) => {
              if (customOnBlur) {
                customOnBlur();
              }
              handleBlur(event);
            }}
          />
        </FormControl>
      )}
    </Field>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.any,
  variant: PropTypes.any,
  helperText: PropTypes.any,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default InputField;

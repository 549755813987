import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import { useRef } from "react";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormikControl from "src/components/formik/FormikControl";
import { AuthAPIService, Slices } from "src/constants";
import { setLoginUser } from "src/slices/authSlice";
import { toastMessage } from "src/slices/commonSlice";
import { ContainedButton } from "src/components/button-group";
import logo from "src/icons/logo.png";
import { sendRequestJSON } from "src/lib/axios";
import { AppDispatch } from "src/store";
import { setItem } from "src/lib/utils";

export const Login = () => {
  const formikRef = useRef();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoggedIn } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );

  if (isLoggedIn) {
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }

  const validationSchemaModel = Yup.object({
    username: Yup.string()
      .min(4, "Username should be minimum 8 characters length")
      .required("Username is required"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const loginAPI = async (userName: string, password: string) => {
    const result = await sendRequestJSON(
      {
        userName,
        password,
      },
      AuthAPIService.LOGIN,
      "post"
    );
    console.log('result',result)
    if (!result.error && result.result.data) {
      dispatch(
        setLoginUser({
          isLoggedIn: true,
          user: { name: result.result.data.data.username, email: result.result.data.data.emailId },
        })
      );
       setItem('auth', JSON.stringify({
        token: result.result.data.data.tokens.accessToken,
        refreshToken: result.result.data.data.tokens.refreshToken,
        currentUserId: result.result.data.data.id
       }))

    } else {
      dispatch(
        toastMessage({
          error: true,
          message: result.result.message,
        })
      );
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ p: 0, m: 0, height: "100vh", backgroundColor: "#FAFAFA" }}
    >
      <Grid item xs={4}>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={async (values) => {
            loginAPI(values.username, values.password);
          }}
        >
          {({ touched, errors, getFieldProps }) => (
            <Form id="Login">
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item xs={12}>
                  <img src={logo} alt="logo" />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    name="username"
                    label="Username"
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    {...getFieldProps("username")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="PasswordInputField"
                    name="password"
                    label="Password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    {...getFieldProps("password")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContainedButton fullWidth type="submit">
                    Submit
                  </ContainedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

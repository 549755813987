import { Chip, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { toastMessage } from "src/slices/commonSlice";
import { selectTotalItems } from "src/slices/configuration/selectors";
import {
  createItem,
  disableItem,
  enableItem,
  fetchAllItems,
} from "src/slices/configuration/thunks";
import { IItem } from "src/slices/configuration/types";
import { AppDispatch } from "src/store";

interface IformInitialValue {
  item: string;
}

export default function ItemPanel() {
  const dispatch = useDispatch<AppDispatch>();

  const formikRef = useRef(null);
  const items: IItem[] = useSelector(selectTotalItems);

  // Form Inital Values
  const formInitialValue: IformInitialValue = {
    item: "",
  };

  // Click Events
  const onClickCreateItem = async (itemName: string) => {
    const item = itemName.trim();
    if (item !== "") {
      const response = await dispatch(createItem(item));
      if (response.payload) {
        dispatch(fetchAllItems());
        formikRef.current.setFieldValue("item", "");
      }
    } else {
      dispatch(
        toastMessage({
          error: true,
          message: "Item cannot be empty",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAllItems());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Formik
        initialValues={formInitialValue}
        innerRef={formikRef}
        onSubmit={() => {}}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form id="Configuration">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="InputField"
                      name="item"
                      label={"Item"}
                      error={Boolean(touched.item && errors.item)}
                      helperText={touched.item && errors.item}
                      {...getFieldProps("item")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ContainedButton
                      size="medium"
                      onClick={() => onClickCreateItem(values.item)}
                    >
                      Add Item
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <MaterialReactTable
                  columns={[
                    {
                      accessorKey: "id",
                      header: "id",
                      size: 40,
                    },
                    {
                      accessorKey: "itemName",
                      header: "Item Name",
                    },
                    {
                      accessorKey: "isActive",
                      header: "Active",
                      Cell: ({ cell }) => (
                        <Chip
                          label={cell.getValue() ? "YES" : "NO"}
                          variant="filled"
                          color={cell.getValue() ? "success" : "error"}
                          sx={{ minWidth: 50 }}
                        />
                      ),
                    },
                  ]}
                  data={items.map((item) => ({
                    id: item.id,
                    itemName: item.name,
                    isActive: item.isActive,
                  }))}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 5, pageIndex: 0 },
                  }}
                  enableRowActions
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      key={2}
                      onClick={async () => {
                        closeMenu();
                        await dispatch(enableItem(row.original.id));
                        dispatch(fetchAllItems());
                      }}
                      sx={{ m: 0 }}
                    >
                      Enable Item
                    </MenuItem>,
                    <MenuItem
                      key={1}
                      onClick={async () => {
                        closeMenu();
                        await dispatch(disableItem(row.original.id));
                        dispatch(fetchAllItems());
                      }}
                      sx={{ m: 0 }}
                    >
                      Disable item
                    </MenuItem>,
                  ]}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

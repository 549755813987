import { combineReducers } from "@reduxjs/toolkit";

import { reducer as loginInformation } from "../slices/authSlice";
import { reducer as commonReducer } from "../slices/commonSlice";
import { reducer as complaintReducer } from "../slices/complaints";
import { reducer as configurationReducer } from "../slices/configuration";
import { reducer as customerReducer } from "../slices/customers";
import { reducer as dashboardReducer } from "../slices/dashboard";
import { reducer as financialDashboardReducer } from "../slices/financial-dashboard";
import { reducer as orderReducer } from "../slices/orders";
import { reducer as uiSettings } from "../slices/uiSettingsSlice";

const appReducer = combineReducers({
  commonReducer,
  uiSettings,
  loginInformation,
  customer: customerReducer,
  complaint: complaintReducer,
  order: orderReducer,
  configuration: configurationReducer,
  dashboard: dashboardReducer,
  financialDashboard: financialDashboardReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "loginInformations/logOutUser") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Chip, Grid, Link } from "@mui/material";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { InfoCard } from "src/components/info-cards/info-card";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { AppDispatch } from "src/store";
import { MultiBarInfoCard } from "src/components/info-cards/multi-bar-info-card";
import { KeyValueCard } from "src/components/key-value-card";
import {
  selectFinancialDashboardData,
  selectTotalSales,
} from "src/slices/financial-dashboard/selector";
import {
  fetchPaymentMethodCount,
  fetchRecentInvoices,
  fetchRevenueByStatus,
  fetchTotalInvoices,
  fetchTotalSales,
} from "src/slices/financial-dashboard/thunks";
import {
  IFetchRevenueByStatusPayload,
  IInvoicesDetail,
} from "src/slices/financial-dashboard/types";
import MaterialReactTable from "material-react-table";
import { dayMonthYearFormat } from "src/lib/utils";
import { Formik } from "formik";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";

export function FinancialDashboardPanel() {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);
  const [invoicesRows, setInvoicesRows] = useState<any[]>([]);
  const [invoiceColumns] = useState<any>([
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <Chip
          label={getInvoiceStatusValue(cell.getValue())}
          variant="filled"
          color={
            getInvoiceStatusValue(cell.getValue()) === "Paid"
              ? "success"
              : "error"
          }
          sx={{ minWidth: 50 }}
        />
      ),
    },
    {
      accessorKey: "invoice",
      header: "Invoice #",
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
    },
    {
      accessorKey: "client",
      header: "Client",
    },
    {
      accessorKey: "balance",
      header: "Balance",
    },
    {
      accessorKey: "invoiceUrl",
      header: "Invoice Url",
      Cell: ({ cell }) => (
        <Link href={cell.getValue()} target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
  ]);

  const fiancialDashboardData = useSelector(selectFinancialDashboardData);
  const totalSales = useSelector(selectTotalSales);

  useEffect(() => {
    if (fiancialDashboardData) {
      const tableComplaintRows: any[] =
        fiancialDashboardData.totalRecentInvoices.map(
          (invoiceDetails: IInvoicesDetail) => ({
            invoice: invoiceDetails.invoice_number,
            client: invoiceDetails.client_name,
            dueDate: dayMonthYearFormat(
              new Date(invoiceDetails.invoice_date_due.toString()),
              true
            ),
            status: invoiceDetails.invoice_status_id,
            balance: invoiceDetails.invoice_balance,
            invoiceUrl: invoiceDetails.invoice_url_key,
          })
        );
      setInvoicesRows(tableComplaintRows);
    }
    // eslint-disable-next-line
  }, [fiancialDashboardData]);

  useEffect(() => {
    dispatch(fetchTotalSales());
    dispatch(fetchTotalInvoices());
    dispatch(fetchPaymentMethodCount());
    const payload: IFetchRevenueByStatusPayload = {
      startDate: format(
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(Date.now()), "yyyy-MM-dd"),
    };
    dispatch(fetchRecentInvoices(payload));
    dispatch(fetchRevenueByStatus(payload));
    // eslint-disable-next-line
  }, []);

  function getInvoiceStatusValue(invoiceStatus: number) {
    switch (invoiceStatus) {
      case 1:
        return "Draft";
      case 2:
        return "Sent";
      case 3:
        return "View";
      case 4:
        return "Paid";
    }
  }

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InfoCard
            title="TOTAL SALES"
            value={fiancialDashboardData.totalSalesCount}
            iconBackgoroundColor="primary.main"
            barChartList={totalSales.map((data) => ({
              ...data,
              value: parseFloat(data.totalRevenue),
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiBarInfoCard
            title="TOTAL INVOICES"
            barTitle1={"Paid"}
            barTitle2="Unpaid"
            title1="TOTAL INVOICES"
            value1={fiancialDashboardData?.totalInvoicesCount}
            iconBackgoroundColor="primary.main"
            barChartList1={fiancialDashboardData.totalInvoices.map((data) => ({
              ...data,
              value: parseInt(data.paidCount),
            }))}
            barChartList2={fiancialDashboardData.totalInvoices.map((data) => ({
              ...data,
              value: parseInt(data.unpaidCount),
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyValueCard
            title="Invoice Overview"
            onClick={(startDate, endDate) => {
              const payload: IFetchRevenueByStatusPayload = {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd"),
              };
              dispatch(fetchRevenueByStatus(payload));
            }}
            data={[
              {
                key: "Draft Revenue",
                value:
                  fiancialDashboardData.totalRevenue.length !== 0
                    ? fiancialDashboardData.totalRevenue[0]?.draftRevenue
                    : "",
              },
              {
                key: "Sent Revenue",
                value:
                  fiancialDashboardData.totalRevenue.length !== 0
                    ? fiancialDashboardData.totalRevenue[0]?.sentRevenue
                    : "",
              },
              {
                key: "Viewed Revenue",
                value:
                  fiancialDashboardData.totalRevenue.length !== 0
                    ? fiancialDashboardData.totalRevenue[0]?.viewedRevenue
                    : "",
              },
              {
                key: "Paid Revenue",
                value:
                  fiancialDashboardData.totalRevenue.length !== 0
                    ? fiancialDashboardData.totalRevenue[0]?.paidRevenue
                    : "",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiBarInfoCard
            title="Transactions"
            title1="Cash Transactions"
            title2="Online Transactions"
            barTitle1={"Cash"}
            barTitle2="Online"
            value1={fiancialDashboardData?.totalCashTransactions}
            value2={fiancialDashboardData?.totalOnlineTransactions}
            iconBackgoroundColor="primary.main"
            barChartList1={fiancialDashboardData.totalPaymentMethodCounts.map(
              (data) => ({
                ...data,
                value: parseInt(data.cashCount),
              })
            )}
            barChartList2={fiancialDashboardData.totalPaymentMethodCounts.map(
              (data) => ({
                ...data,
                value: parseInt(data.bankTransferCount),
              })
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MaterialReactTable
            columns={invoiceColumns}
            data={invoicesRows}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  p: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                <Formik
                  initialValues={{
                    startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                    endDate: new Date(Date.now()),
                  }}
                  innerRef={formikRef}
                  onSubmit={() => {}}
                >
                  {({ values, touched, errors, getFieldProps }) => (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikControl
                          control="CalendarTime"
                          label="Start Date"
                          dateOrTimeOnly="date"
                          name="startDate"
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          {...getFieldProps("startDate")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikControl
                          control="CalendarTime"
                          label="End Date"
                          dateOrTimeOnly="date"
                          name="endDate"
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          {...getFieldProps("endDate")}
                        />
                      </Grid>
                      <Grid item>
                        <ContainedButton
                          onClick={() => {
                            const payload = {
                              startDate: format(values.startDate, "yyyy-MM-dd"),
                              endDate: format(values.endDate, "yyyy-MM-dd"),
                            };
                            dispatch(fetchRecentInvoices(payload));
                          }}
                        >
                          Search
                        </ContainedButton>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </Box>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

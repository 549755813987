import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDashboardData,
  fetchTotalComplaints,
  fetchTotalCustomers,
  fetchTotalOrders,
  fetchTotalOrdersByServices,
  fetchTotalOrdersByStatus,
} from "./thunks";
import { IDashboardSlice } from "./types";

const initialState: IDashboardSlice = {
  complaints: [],
  customers: [],
  orders: [],
  totalOrders: [],
  totalComplaints: [],
  totalCustomers: [],
  totalOrdersByServices: [],
  totalOrdersByStatus: [],
  isError: false,
  isLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchDashboardData
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = action?.payload?.customer?.rows;
      state.orders = action?.payload?.order?.rows;
      state.complaints = action?.payload?.complaint?.rows;
    });
    builder.addCase(fetchDashboardData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchTotalOrders
    builder.addCase(fetchTotalOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalOrders = action.payload;
    });
    builder.addCase(fetchTotalOrders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchTotalCustomers
    builder.addCase(fetchTotalCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalCustomers = action.payload;
    });
    builder.addCase(fetchTotalCustomers.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchTotalComplaints
    builder.addCase(fetchTotalComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalComplaints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalComplaints = action.payload;
    });
    builder.addCase(fetchTotalComplaints.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchTotalOrdersByStatus
    builder.addCase(fetchTotalOrdersByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalOrdersByStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalOrdersByStatus = action.payload;
    });
    builder.addCase(fetchTotalOrdersByStatus.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchTotalOrdersByServices
    builder.addCase(fetchTotalOrdersByServices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalOrdersByServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalOrdersByServices = action.payload;
    });
    builder.addCase(fetchTotalOrdersByServices.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { reducer } = dashboardSlice;

import { addDays, format, isSunday } from "date-fns";

export const Utils = {
  isSundayBetween: (startDate: Date, endDate: Date) => {
    const currentDate = new Date(startDate);
    for (let i = 1; i <= 3; i++) {
      const nextDay = addDays(currentDate, i);
      if (isSunday(nextDay)) {
        return true;
      }
    }
    return false;
  },
};

export const bytesToSize = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const dayMonthYearFormat = (date: Date, showTime: boolean = false) => {
  return showTime
    ? format(date, "dd/MM/yyyy hh:mm:ss aa")
    : format(date, "dd/MM/yyyy");
};

export const setItem = (name:string, value:any) => {
  localStorage.setItem(encodeURI(name), encodeURI(value))
}

export const getItem = (name:string) => {
  const item =  localStorage.getItem(encodeURI(name));
  return decodeURI(item)
}

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IFinancialDashboardSlice } from "./types";

const selectSelf = (state: RootState) => state;
const selecFinancialDashboard = createSelector(
  selectSelf,
  (state) => state.financialDashboard
);

export const selectIsFinancialDashboardLoading = createSelector(
  selecFinancialDashboard,
  (financialDashboard: IFinancialDashboardSlice) => financialDashboard.isLoading
);

export const selectFinancialDashboardData = createSelector(
  selecFinancialDashboard,
  (financialDashboard: IFinancialDashboardSlice) => ({
    totalSales: financialDashboard.totalSales,
    totalInvoices: financialDashboard.totalInvoices,
    totalRevenue: financialDashboard.totalRevenue,
    totalPaymentMethodCounts: financialDashboard.totalPaymentMethodCounts,
    totalRecentInvoices: financialDashboard.totalRecentInvoices,
    totalCashTransactions: financialDashboard.totalPaymentMethodCounts.reduce(
      (total, val) => total + parseInt(val.cashCount),
      0
    ),
    totalOnlineTransactions: financialDashboard.totalPaymentMethodCounts.reduce(
      (total, val) => total + parseInt(val.bankTransferCount),
      0
    ),
    totalInvoicesCount: financialDashboard.totalInvoices.reduce(
      (total, val) => total + val.invoiceCount,
      0
    ),
    totalSalesCount: financialDashboard.totalSales?.reduce(
      (total, val) => total + parseFloat(val.totalRevenue),
      0
    ),
  })
);

export const selectTotalSales = createSelector(
  selecFinancialDashboard,
  (financialDashboard: IFinancialDashboardSlice) =>
    financialDashboard.totalSales
);

export const selectDownloadedReports = createSelector(
  selecFinancialDashboard,
  (financialDashboard: IFinancialDashboardSlice) =>
    financialDashboard.downloadedReports
);

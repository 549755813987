import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import { Outlet, useLocation } from "react-router-dom";
import { DrawerNavigationList } from "./components/DrawerNavigationList";
import { useState } from "react";
import logo from "src/icons/logo.png";
import CustomLoader from "./components/CustomLoader";
import { useSelector } from "react-redux";
import { Copyright } from "./components/Copyright";
import { AppBar } from "./components/AppBar";
import { AppDrawer } from "./components/AppDrawer";
import { Slices } from "./constants";
import CToastInfoDialog from "./components/CToastInfoDialog";
import usePageTitle from "./hooks/usePageTitle";
import { RootState } from "./store";
import { selectIsCustomerLoading } from "./slices/customers/selectors";
import { selectIsComplaintLoading } from "./slices/complaints/selectors";
import { selectIsOrderLoading } from "./slices/orders/selectors";
import { selectIsConfigurationLoading } from "./slices/configuration/selectors";
import { selectIsDashboardLoading } from "./slices/dashboard/selectors";
import { selectIsFinancialDashboardLoading } from "./slices/financial-dashboard/selector";

const drawerWidth: number = 240;

function DashboardContent() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const headingTitle = usePageTitle(location.pathname);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        elevation={0}
        open={open}
        drawerWidth={drawerWidth}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {headingTitle}
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppDrawer variant="permanent" open={open} drawerWidth={drawerWidth}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="logo"
            height={50}
            justifySelf="center"
          />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <DrawerNavigationList />
          {/* <Divider sx={{ my: 1 }} /> */}
          {/* {secondaryListItems} */}
        </List>
      </AppDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Outlet />
          </Paper>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default function App() {
  const isCustomerLoading = useSelector((state: RootState) =>
    selectIsCustomerLoading(state)
  );
  const isComplaintLoading = useSelector((state: RootState) =>
    selectIsComplaintLoading(state)
  );
  const isOrderLoading = useSelector((state: RootState) =>
    selectIsOrderLoading(state)
  );
  const isConfigurationLoading = useSelector((state: RootState) =>
    selectIsConfigurationLoading(state)
  );
  const isDashboardLoading = useSelector((state: RootState) =>
    selectIsDashboardLoading(state)
  );
  const isFinancialDashboardLoading = useSelector((state: RootState) =>
    selectIsFinancialDashboardLoading(state)
  );
  const { isLoading } = useSelector(
    (state: RootState) => state[Slices.COMMON_REDUCER]
  );
  return (
    <>
      {isLoading ||
      isCustomerLoading ||
      isComplaintLoading ||
      isConfigurationLoading ||
      isDashboardLoading ||
      isFinancialDashboardLoading ||
      isOrderLoading ? (
        <CustomLoader />
      ) : null}
      <CToastInfoDialog />
      <DashboardContent />
    </>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { BusinessProcessAPIService } from "src/constants";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { IAddServiceItemPricingPayload, IAddUnitPricePayload } from "./types";

export const fetchAllServices = createAsyncThunk(
  "configuration/fetchAllServices",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_ALL_SERVICES_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchServices = createAsyncThunk(
  "configuration/fetchServices",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_SERVICES_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchItems = createAsyncThunk(
  "configuration/fetchItems",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_ITEMS_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchAllItems = createAsyncThunk(
  "configuration/fetchAllItems",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_ALL_ITEMS_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const createItem = createAsyncThunk(
  "configuration/createItem",
  async (itemName: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        { itemName },
        BusinessProcessAPIService.CREATE_ITEM,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
        const { data } = response.result.data;
        return data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const createService = createAsyncThunk(
  "configuration/createService",
  async (serviceName: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        { serviceName },
        BusinessProcessAPIService.CREATE_SERVICE,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const disableService = createAsyncThunk(
  "configuration/disableService",
  async (serviceId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        { serviceId },
        BusinessProcessAPIService.DISABLE_SERVICE,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const enableService = createAsyncThunk(
  "configuration/enableService",
  async (serviceId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        { serviceId },
        BusinessProcessAPIService.ENABLE_SERVICE,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const enableItem = createAsyncThunk(
  "configuration/enableItem",
  async (itemId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        { itemId },
        BusinessProcessAPIService.ENABLE_ITEM,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const disableItem = createAsyncThunk(
  "configuration/disableItem",
  async (itemId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        { itemId },
        BusinessProcessAPIService.DISABLE_ITEM,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchServiceItemPrices = createAsyncThunk(
  "configuration/fetchServiceItemPrices",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_PRICE,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const addServiceItemPricing = createAsyncThunk(
  "configuration/addServiceItemPricing",
  async (addPricingPayload: IAddServiceItemPricingPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        addPricingPayload,
        BusinessProcessAPIService.SET_PRICE,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchExpenseTypes = createAsyncThunk(
  "configuration/fetchExpenseTypes",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_EXPENSE_TYPES,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchUnitPriceItems = createAsyncThunk(
  "configuration/fetchUnitPriceItems",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        BusinessProcessAPIService.GET_UNIT_PRICE_ITEMS,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const addUnitPrice = createAsyncThunk(
  "configuration/addUnitPrice",
  async (addPricingPayload: IAddUnitPricePayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        addPricingPayload,
        BusinessProcessAPIService.ADD_UNIT_PRICE,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

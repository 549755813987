import { useState, useEffect } from "react";
import { RoutePaths } from "src/constants";

const usePageTitle = (pathname) => {
  const [headingTitle, setHeadingTitle] = useState("Dashboard");
  useEffect(() => {
    if (pathname === "/") {
      setHeadingTitle("Dashboard");
    } else if (pathname === RoutePaths.FINANCIAL_REPORT) {
      setHeadingTitle("Financial Report");
    } else if (pathname === RoutePaths.SEARCH_CUSTOMER) {
      setHeadingTitle("Search Customer or Create Customer");
    } else if (pathname === RoutePaths.CREATE_CUSTOMER) {
      setHeadingTitle("Create Customer");
    } else if (pathname.includes(RoutePaths.UPDATE_CUSTOMER)) {
      setHeadingTitle("Update Customer");
    } else if (pathname === RoutePaths.ORDER_LISTING) {
      setHeadingTitle("Order Listing");
    } else if (pathname === RoutePaths.UPDATE_ORDER) {
      setHeadingTitle("Update Order");
    } else if (pathname.includes(RoutePaths.CUSTOMER_DETAILS)) {
      setHeadingTitle("Customer Details");
    } else if (pathname.includes(RoutePaths.ORDER_DETAILS)) {
      setHeadingTitle("Order Details");
    } else if (pathname.includes(RoutePaths.CUSTOMER_ORDER_INFO)) {
      setHeadingTitle("Customer Order Info");
    } else if (pathname === RoutePaths.CONFIGURATION) {
      setHeadingTitle("Configurations");
    } else {
      setHeadingTitle("Complaint Listing");
    }
  }, [pathname]);

  return headingTitle;
};

export default usePageTitle;

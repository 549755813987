import { createSlice } from "@reduxjs/toolkit";

import {
  createUpdateComplaint,
  fetchComplaintCategories,
  fetchComplaints,
  fetchComplaintByComplaintId,
  fetchComplaintByCustomerId,
  uploadComplaintAttachments,
} from "./thunks";
import { IComplaintSlice } from "./types";

const initialState: IComplaintSlice = {
  complaints: [],
  complaintCategories: [],
  customerComplaints: [],
  selectedComplaint: null,
  isLoading: false,
  isError: false,
};

export const complaintSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {
    emptySelectedComplaintState: (state) => {
      state.selectedComplaint = null;
    },
  },
  extraReducers: (builder) => {
    // fetchComplaints
    builder.addCase(fetchComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchComplaints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.complaints = action.payload;
    });
    builder.addCase(fetchComplaints.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchComplaintCategories
    builder.addCase(fetchComplaintCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchComplaintCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.complaintCategories = action.payload;
    });
    builder.addCase(fetchComplaintCategories.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // uploadComplaintAttachments
    builder.addCase(uploadComplaintAttachments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadComplaintAttachments.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(uploadComplaintAttachments.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // createUpdateComplaint
    builder.addCase(createUpdateComplaint.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUpdateComplaint.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createUpdateComplaint.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchComplaintByCustomerId
    builder.addCase(fetchComplaintByCustomerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchComplaintByCustomerId.fulfilled, (state, action) => {
      state.customerComplaints = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchComplaintByCustomerId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchComplaintByComplaintId
    builder.addCase(fetchComplaintByComplaintId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchComplaintByComplaintId.fulfilled, (state, action) => {
      state.selectedComplaint = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchComplaintByComplaintId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { emptySelectedComplaintState } = complaintSlice.actions;
export const { reducer } = complaintSlice;

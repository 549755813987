import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoneyIcon from "@mui/icons-material/Money";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import ThreePIcon from "@mui/icons-material/ThreeP";
import { Chip, Grid, IconButton, MenuItem, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { InfoCard } from "src/components/info-cards/info-card";
import { OrderStatusChip } from "src/components/pages/order/order-status-chip";
import { RoutePaths } from "src/constants";
import { IComplaint } from "src/slices/complaints/types";
import {
  selectDashboardData,
  selectTotalComplaints,
  selectTotalCustomers,
  selectTotalOrders,
  selectTotalOrdersByServices,
  selectTotalOrdersByStatus,
} from "src/slices/dashboard/selectors";
import {
  fetchDashboardData,
  fetchTotalComplaints,
  fetchTotalCustomers,
  fetchTotalOrders,
  fetchTotalOrdersByServices,
  fetchTotalOrdersByStatus,
} from "src/slices/dashboard/thunks";
import { selectFilteredOrders } from "src/slices/orders/selectors";
import { fetchFilteredOrders } from "src/slices/orders/thunks";
import { IFetchFilteredOrdersPayload } from "src/slices/orders/types";
import { AppDispatch } from "src/store";
import { dayMonthYearFormat } from "src/lib/utils";
import { KeyValueListCard } from "src/components/info-cards/key-value-list-card";
import { BarChartCard } from "src/components/info-cards/bar-chart-card";

export function OperationalDashboardPanel() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const formikRef = useRef(null);
  const filteredOrder = useSelector(selectFilteredOrders);
  const dashboardData = useSelector(selectDashboardData);
  const totalOrders = useSelector(selectTotalOrders);
  const totalComplaints = useSelector(selectTotalComplaints);
  const totalCustomers = useSelector(selectTotalCustomers);
  const totalOrdersByStatus = useSelector(selectTotalOrdersByStatus);
  const totalOrdersByServices = useSelector(selectTotalOrdersByServices);

  const [orderColumns] = useState<any>([
    { accessorKey: "orderId", header: "Order#" },
    {
      accessorKey: "customerName",
      header: "Customer Name",
    },
    {
      accessorKey: "pickUpDate",
      header: "Pickup Date",
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
    },
    {
      accessorKey: "orderStatus",
      header: "Order Status",
      Cell: ({ cell }) => <OrderStatusChip orderStatus={cell.getValue()} />,
    },
    {
      accessorKey: "pickup",
      header: "Pickup",
      Cell: ({ cell }) => (
        <>
          {!["", null, undefined].includes(cell.getValue()) ? (
            <Tooltip title="Copy">
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(cell.getValue().toString())
                }
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      ),
    },
    {
      accessorKey: "delivery",
      header: "Delivery",
      Cell: ({ cell }) => (
        <>
          {!["", null, undefined].includes(cell.getValue()) ? (
            <Tooltip title="Copy">
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(cell.getValue().toString())
                }
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      ),
    },
  ]);

  const [complaintColumns] = useState<any>([
    {
      accessorKey: "order",
      header: "Order#",
    },
    {
      accessorKey: "customerName",
      header: "Customer Name",
    },
    {
      accessorKey: "complaintDate",
      header: "Complaint Date",
    },
    {
      accessorKey: "resolved",
      header: "Resolved",
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue() === "true" ? "YES" : "NO"}
          variant="filled"
          color={cell.getValue() === "true" ? "success" : "error"}
          sx={{ minWidth: 50 }}
        />
      ),
    },
  ]);

  const [orderRows, setOrderRows] = useState<any[]>([]);
  const [complaintRows, setComplaintRows] = useState<any[]>([]);

  useEffect(() => {
    const payload: IFetchFilteredOrdersPayload = {
      startDate: format(new Date(Date.now()), "yyyy-MM-dd"),
      endDate: format(new Date(Date.now()), "yyyy-MM-dd"),
    };
    dispatch(fetchFilteredOrders(payload));
    dispatch(fetchDashboardData());
    dispatch(fetchTotalOrders());
    dispatch(fetchTotalCustomers());
    dispatch(fetchTotalComplaints());
    dispatch(fetchTotalOrdersByStatus());
    dispatch(fetchTotalOrdersByServices());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filteredOrder.length > 0) {
      const tableOrderRows: any[] = filteredOrder.map((order) => ({
        id: order.id,
        customerId: order.customerId,
        orderId: `LX-${order.id}`,
        customerName: order.customerName,
        pickupAddress: order.pickupAddress,
        deliveryAddress: order.deliveryAddress,
        orderStatus: order.orderStatus,
        servicesItems: order.servicesItems,
        pickup: order.pickupAddressGoogleLocation,
        delivery: order.deliveryAddressGoogleLocation,
        pickUpDate: dayMonthYearFormat(new Date(order.pickUpDate)),
        dueDate: dayMonthYearFormat(new Date(order.dueDate)),
      }));
      setOrderRows(tableOrderRows);
    }
  }, [filteredOrder]);

  useEffect(() => {
    if (dashboardData) {
      const tableComplaintRows: any[] = dashboardData.complaint.map(
        (complaint: IComplaint) => ({
          order: `LX-${complaint.orderId}`,
          customerName: complaint.name,
          complaintDate: dayMonthYearFormat(
            new Date(complaint.lastModifiedDate),
            true
          ),
          resolved: complaint.isResolved.toString(),
        })
      );
      setComplaintRows(tableComplaintRows);
    }
    // eslint-disable-next-line
  }, [dashboardData]);

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InfoCard
            title="TOTAL ORDERS"
            value={dashboardData?.orderCount}
            icon={<MoneyIcon />}
            iconBackgoroundColor="primary.main"
            barChartList={totalOrders.map((data) => ({
              ...data,
              value: parseInt(data.totalorders),
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoCard
            title="TOTAL CUSTOMERS"
            value={dashboardData?.customerCount}
            icon={<PeopleIcon />}
            iconBackgoroundColor="primary.main"
            barChartList={totalCustomers.map((data) => ({
              ...data,
              value: parseInt(data.totalcustomers),
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoCard
            title="TOTAL COMPLAINTS"
            value={dashboardData?.complaintCount}
            icon={<ThreePIcon />}
            iconBackgoroundColor="primary.main"
            barChartList={totalComplaints.map((data) => ({
              ...data,
              value: parseInt(data.totalcomplaints),
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyValueListCard
            title="ORDERS BY STATUS"
            keyValueList={totalOrdersByStatus.map((data) => ({
              key: data.name,
              value: data.totalstatus,
              onClick: () => {
                navigate(RoutePaths.ORDER_LISTING, {
                  state: { defaultOrderStatus: data.name },
                });
              },
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <BarChartCard
            title="ORDERS BY SERVICES"
            barChartList={totalOrdersByServices.map((data) => ({
              xTitle: data.name,
              value: parseInt(data.totalservices),
            }))}
          />
        </Grid>

        <Grid item xs={12}>
          <MaterialReactTable
            columns={orderColumns}
            data={orderRows}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  p: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                <Formik
                  initialValues={{
                    startDate: new Date(Date.now()),
                    endDate: new Date(Date.now()),
                  }}
                  innerRef={formikRef}
                  onSubmit={() => {}}
                >
                  {({ values, touched, errors, getFieldProps }) => (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikControl
                          control="CalendarTime"
                          label="Start Date"
                          dateOrTimeOnly="date"
                          name="startDate"
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          {...getFieldProps("startDate")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikControl
                          control="CalendarTime"
                          label="End Date"
                          dateOrTimeOnly="date"
                          name="endDate"
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          {...getFieldProps("endDate")}
                        />
                      </Grid>
                      <Grid item>
                        <ContainedButton
                          onClick={() => {
                            const payload: IFetchFilteredOrdersPayload = {
                              startDate: format(values.startDate, "yyyy-MM-dd"),
                              endDate: format(values.endDate, "yyyy-MM-dd"),
                            };
                            dispatch(fetchFilteredOrders(payload));
                          }}
                        >
                          Search
                        </ContainedButton>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </Box>
            )}
            enableRowActions
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                key={1}
                onClick={() => {
                  closeMenu();
                  navigate(`${RoutePaths.ORDER_DETAILS}/${row.original.id}`);
                }}
                sx={{ m: 0 }}
              >
                Order Details
              </MenuItem>,
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <MaterialReactTable
            columns={complaintColumns}
            data={complaintRows}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

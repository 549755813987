import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrderAPIService } from "src/constants";
import { sendRequestFiles, sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { IComplaint, IComplaintPayload } from "./types";

export const fetchComplaints = createAsyncThunk(
  "conplaints/fetchComplaints",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        OrderAPIService.GET_COMPLAINTS,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchComplaintByCustomerId = createAsyncThunk(
  "conplaints/fetchComplaintByCustomerId",
  async (customerId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          customerId,
        },
        OrderAPIService.GET_COMPLAINT_BY_CUSTOMER_ID,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchComplaintByComplaintId = createAsyncThunk(
  "conplaints/fetchComplaintByComplaintId",
  async (complaintId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          complaintId,
        },
        OrderAPIService.GET_COMPLAINT_BY_ID,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data[0];
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchComplaintCategories = createAsyncThunk(
  "conplaints/fetchComplaintCategories",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        OrderAPIService.GET_COMPLAINTS_CATEGORY,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const uploadComplaintAttachments = createAsyncThunk(
  "conplaints/uploadComplaintAttachments",
  async (complaintPayload: IComplaintPayload, { dispatch }) => {
    try {
      const response = await sendRequestFiles(
        complaintPayload.files,
        OrderAPIService.UPLOAD_ATTACHMENT
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return;
      }

      const { data } = response.result.data;
      const attachments = data.map((compData) => ({
        fileName: compData.originalname,
        fileUrl: compData.location,
        fileType: compData.mimetype?.split("/")[0],
      }));
      const complaint = {
        ...complaintPayload.complaintData,
        attachments: [
          ...complaintPayload.complaintData.attachments,
          ...attachments,
        ],
      };
      const complaintResponse = await dispatch(
        createUpdateComplaint(complaint)
      );
      return complaintResponse.payload;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const createUpdateComplaint = createAsyncThunk(
  "conplaints/createUpdateComplaint",
  async (compaint: IComplaint, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        compaint,
        OrderAPIService.CREATE_UPDATE_COMPLAINT,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

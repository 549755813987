import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { REGEX_IS_NUMBER } from "src/constants";
import { toastMessage } from "src/slices/commonSlice";
import {
  selectItems,
  selectServiceItemPrices,
  selectServices,
} from "src/slices/configuration/selectors";
import {
  addServiceItemPricing,
  fetchItems,
  fetchServiceItemPrices,
  fetchServices,
} from "src/slices/configuration/thunks";
import {
  IAddServiceItemPricingPayload,
  IItem,
  IServiceItemPrice,
  IService,
} from "src/slices/configuration/types";
import { AppDispatch } from "src/store";

interface IformInitialValue {
  service: string;
  item: string;
  price: string;
}

export default function PriceMappingPanel() {
  const dispatch = useDispatch<AppDispatch>();

  const formikRef = useRef(null);
  const services: IService[] = useSelector(selectServices);
  const items: IItem[] = useSelector(selectItems);
  const serviceItemPrices: IServiceItemPrice[] = useSelector(
    selectServiceItemPrices
  );

  // Form Inital Values
  const formInitialValue: IformInitialValue = {
    service: "",
    item: "",
    price: "",
  };

  // Click Events
  const onClickAddPricing = async (values: IformInitialValue) => {
    const isNum = REGEX_IS_NUMBER.test(values.price);
    if (isNum) {
      const priceMapping: IAddServiceItemPricingPayload = {
        serviceId: values.service,
        itemId: values.item,
        price: values.price,
      };
      const response = await dispatch(addServiceItemPricing(priceMapping));
      if (response.payload) {
        dispatch(fetchServiceItemPrices());
        formikRef.current.setFieldValue("service", "");
        formikRef.current.setFieldValue("item", "");
        formikRef.current.setFieldValue("price", "");
      }
    } else {
      dispatch(
        toastMessage({
          error: true,
          message: "Price must be a valid number",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchItems());
    dispatch(fetchServiceItemPrices());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Formik
        initialValues={formInitialValue}
        innerRef={formikRef}
        onSubmit={(values) => {
          onClickAddPricing(values);
        }}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form id="Configuration">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3} pb={3} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="SelectField"
                      name="service"
                      label={"Service"}
                      required
                      options={services?.map((service) => ({
                        value: service.id,
                        label: service.name,
                      }))}
                      error={Boolean(touched.service && errors.service)}
                      helperText={touched.service && errors.service}
                      {...getFieldProps("service")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="SelectField"
                      name="item"
                      label={"Item"}
                      required
                      options={items?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      error={Boolean(touched.item && errors.item)}
                      helperText={touched.item && errors.item}
                      {...getFieldProps("item")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="InputField"
                      name="price"
                      label={"Price"}
                      required
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      {...getFieldProps("price")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ContainedButton size="medium" type="submit">
                      Add Pricing
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MaterialReactTable
                      columns={[
                        {
                          accessorKey: "serviceName",
                          header: "Service Name",
                        },
                        {
                          accessorKey: "itemName",
                          header: "Item Name",
                        },
                        {
                          accessorKey: "price",
                          header: "Price",
                        },
                      ]}
                      data={serviceItemPrices?.map((mapping) => ({
                        serviceName: mapping.serviceName,
                        itemName: mapping.itemName,
                        price: mapping.price,
                      }))}
                      initialState={{
                        density: "compact",
                        pagination: { pageSize: 5, pageIndex: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

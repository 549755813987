import { Grid, IconButton, Link, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { GENDER, getInvoiceStatusValue } from "src/constants";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppDispatch } from "src/store";
import { selectSelectedOrderDetails } from "src/slices/orders/selectors";
import { fetchOrderDetailsById } from "src/slices/orders/thunks";
import { emptyOrderDetailsState } from "src/slices/orders";
import {
  ICustomerOrderedService,
  IOrderDetails,
} from "src/slices/orders/types";
import { CustomerOrderedServicesGrid } from "src/components/pages/order/customer-ordered-services-grid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CKeyValueList from "src/components/CKeyValueList";

interface IformInitialValue {
  customerFullName: string;
  gender: string;
  dateOfBirth: Date;
  contactNumber: string;
  alternateNumber: string;
  emailAddress: string;
  address: string;
  addressLocation: string;
  pickupAddress: string;
  pickupLocation: string;
  deliveryAddress: string;
  deliveryLocation: string;
  dueDate: Date;
  pickupDate: Date;
  servicesCustomerWants: string;
  servicesForItems: string;
  estimatedNumberOfItems: string;
  specialInstruction: string;
  invoiceNumber: string;
  orderStatus: string;
}

export function OrderDetails() {
  const { orderId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const selectedOrderDetails = useSelector(selectSelectedOrderDetails);

  const formikRef = useRef(null);

  console.log('selectedOrderDetails',selectedOrderDetails?.revenueDetails);

  // Services
  const [rows, setRows] = useState<ICustomerOrderedService[]>([]);

  // Inital Values

  const formInitialValue: IformInitialValue = {
    customerFullName: "",
    gender: "",
    dateOfBirth: new Date(Date.now()),
    contactNumber: "",
    alternateNumber: "",
    emailAddress: "",
    address: "",
    addressLocation: "",
    pickupAddress: "",
    pickupLocation: "",
    deliveryAddress: "",
    deliveryLocation: "",
    dueDate: new Date(Date.now()),
    pickupDate: new Date(Date.now()),
    servicesCustomerWants: "",
    servicesForItems: "",
    estimatedNumberOfItems: "",
    specialInstruction: "",
    invoiceNumber: "",
    orderStatus: "1",
  };

  const loadOrderData = async (orderDetails: IOrderDetails) => {
    // populate values
    formikRef.current.setFieldValue(
      "customerFullName",
      orderDetails.customerName
    );
    formikRef.current.setFieldValue(
      "gender",
      orderDetails.customerGender ? GENDER[orderDetails.customerGender] : ""
    );
    formikRef.current.setFieldValue("emailAddress", orderDetails.customerEmail);
    formikRef.current.setFieldValue(
      "contactNumber",
      orderDetails.customerPrimaryNumber
    );
    formikRef.current.setFieldValue(
      "alternateNumber",
      orderDetails.customerSecondaryNumber ?? ""
    );
    formikRef.current.setFieldValue(
      "dateOfBirth",
      orderDetails.customerDOB ?? new Date(Date.now())
    );
    formikRef.current.setFieldValue("dueDate", orderDetails.dueDate);
    formikRef.current.setFieldValue("pickupDate", orderDetails.pickUpDate);

    formikRef.current.setFieldValue(
      "deliveryAddress",
      orderDetails.deliveryAddress
    );
    formikRef.current.setFieldValue(
      "pickupAddress",
      orderDetails.pickupAddress
    );

    formikRef.current.setFieldValue(
      "deliveryLocation",
      orderDetails.deliveryAddressGoogleLocation
    );

    formikRef.current.setFieldValue(
      "pickupLocation",
      orderDetails.pickupAddressGoogleLocation
    );

    formikRef.current.setFieldValue(
      "invoiceNumber",
      orderDetails?.invoicePlaneRefId ?? ""
    );

    formikRef.current.setFieldValue("orderStatus", orderDetails?.statusId);

    setRows(
      orderDetails.servicesItems.map((service) => ({
        ...service,
        price: service.price,
      }))
    );
  };

  useEffect(() => {
    dispatch(fetchOrderDetailsById(orderId));
    return () => {
      dispatch(emptyOrderDetailsState());
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (selectedOrderDetails) {
      loadOrderData(selectedOrderDetails);
    }
  }, [selectedOrderDetails]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          initialValues={formInitialValue}
          innerRef={formikRef}
          onSubmit={() => {}}
        >
          {({ values, touched, errors, getFieldProps }) => (
            <Form id="CreateOrder">
              {/* Personal Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Personal Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    disabled
                    name="customerFullName"
                    label={"Customer Full Name"}
                    error={Boolean(
                      touched.customerFullName && errors.customerFullName
                    )}
                    helperText={
                      touched.customerFullName && errors.customerFullName
                    }
                    {...getFieldProps("customerFullName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    name="gender"
                    label="Gender"
                    disabled
                    options={[
                      {
                        label: "Male",
                        value: "Male",
                      },
                      {
                        label: "Female",
                        value: "Female",
                      },
                    ]}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    {...getFieldProps("gender")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Date of Birth"
                    dateOrTimeOnly="date"
                    name="dateOfBirth"
                    disabled
                    error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    {...getFieldProps("dateOfBirth")}
                  />
                </Grid>
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Contact Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="contactNumber"
                    label="Contact Number"
                    disabled
                    error={Boolean(
                      touched.contactNumber && errors.contactNumber
                    )}
                    helperText={touched.contactNumber && errors.contactNumber}
                    {...getFieldProps("contactNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="alternateNumber"
                    label="Alternate Number"
                    disabled
                    error={Boolean(
                      touched.alternateNumber && errors.alternateNumber
                    )}
                    helperText={
                      touched.alternateNumber && errors.alternateNumber
                    }
                    {...getFieldProps("alternateNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="emailAddress"
                    label="Email Address"
                    disabled
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    helperText={touched.emailAddress && errors.emailAddress}
                    {...getFieldProps("emailAddress")}
                  />
                </Grid>
              </Grid>

              {/* Invoice */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">Invoice</CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="invoiceNumber"
                    disabled
                    label={"Invoice#"}
                    error={Boolean(
                      touched.invoiceNumber && errors.invoiceNumber
                    )}
                    helperText={touched.invoiceNumber && errors.invoiceNumber}
                    {...getFieldProps("invoiceNumber")}
                  />
                </Grid>
              </Grid>

              {/* Pickup and Delivery Information */}
              <Grid container spacing={1} pb={3} alignItems="center">
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Pickup and Delivery Information
                  </CTypography>
                </Grid>

                {/* Pickup */}
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    control="InputField"
                    name="pickupAddress"
                    label="Pickup Address"
                    disabled
                    error={Boolean(
                      touched.pickupAddress && errors.pickupAddress
                    )}
                    helperText={touched.pickupAddress && errors.pickupAddress}
                    {...getFieldProps("pickupAddress")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <FormikControl
                        control="InputField"
                        name="pickupLocation"
                        label="Pickup Google Location"
                        disabled
                        error={Boolean(
                          touched.pickupLocation && errors.pickupLocation
                        )}
                        helperText={
                          touched.pickupLocation && errors.pickupLocation
                        }
                        {...getFieldProps("pickupLocation")}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Copy">
                        <IconButton
                          color="inherit"
                          disabled={values.pickupLocation === ""}
                          onClick={() =>
                            navigator.clipboard.writeText(values.pickupLocation)
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Delivery */}
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    control="InputField"
                    name="deliveryAddress"
                    label="Delivery Address"
                    disabled
                    error={Boolean(
                      touched.deliveryAddress && errors.deliveryAddress
                    )}
                    helperText={
                      touched.deliveryAddress && errors.deliveryAddress
                    }
                    {...getFieldProps("deliveryAddress")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <FormikControl
                        control="InputField"
                        name="deliveryLocation"
                        label="Pickup Google Location"
                        disabled
                        error={Boolean(
                          touched.deliveryLocation && errors.deliveryLocation
                        )}
                        helperText={
                          touched.deliveryLocation && errors.deliveryLocation
                        }
                        {...getFieldProps("deliveryLocation")}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Copy">
                        <IconButton
                          color="inherit"
                          disabled={values.deliveryLocation === ""}
                          onClick={() =>
                            navigator.clipboard.writeText(
                              values.deliveryLocation
                            )
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Pickup Date"
                    dateOrTimeOnly="date"
                    name="pickupDate"
                    disabled
                    error={Boolean(touched.pickupDate && errors.pickupDate)}
                    helperText={touched.pickupDate && errors.pickupDate}
                    {...getFieldProps("pickupDate")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Due Date"
                    dateOrTimeOnly="date"
                    name="dueDate"
                    disabled
                    error={Boolean(touched.dueDate && errors.dueDate)}
                    helperText={touched.dueDate && errors.dueDate}
                    {...getFieldProps("dueDate")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="specialInstruction"
                    label="Special Instruction"
                    disabled
                    error={Boolean(
                      touched.specialInstruction && errors.specialInstruction
                    )}
                    helperText={
                      touched.specialInstruction && errors.specialInstruction
                    }
                    {...getFieldProps("specialInstruction")}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">Order Status</CTypography>
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="SelectField"
                    name="orderStatus"
                    label="Order Status"
                    disabled
                    options={[
                      {
                        label: "New Order",
                        value: "1",
                      },
                      {
                        label: "Ready for Pickup",
                        value: "2",
                      },
                      {
                        label: "In Progress",
                        value: "3",
                      },
                      {
                        label: "Ready for Delivery",
                        value: "4",
                      },
                      {
                        label: "Delivered",
                        value: "5",
                      },
                      {
                        label: "Pickup Failed",
                        value: "6",
                      },
                      {
                        label: "Delivery Failed",
                        value: "7",
                      },
                      {
                        label: "Cancelled",
                        value: "8",
                      },
                    ]}
                    error={Boolean(touched.orderStatus && errors.orderStatus)}
                    helperText={touched.orderStatus && errors.orderStatus}
                    {...getFieldProps("orderStatus")}
                  />
                </Grid>
              </Grid>

              {/* Order Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">Order Information</CTypography>
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9}>
                  <CustomerOrderedServicesGrid rows={rows} />
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg={3}>
                  {selectedOrderDetails?.revenueDetails?.map((revenue) => (
                    <CKeyValueList
                      labelWidth={150}
                      size="small"
                      list={[
                        {
                          key: "Invoice Date",
                          value: revenue.invoice_date_created,
                        },
                        {
                          key: "Due Date",
                          value: revenue.invoice_date_due,
                        },
                        {
                          key: "Invoice URL",
                          value: (
                            <Link
                              href={revenue.invoice_url_key}
                              target="_blank"
                            >
                              <PictureAsPdfIcon />
                            </Link>
                          ),
                        },
                        {
                          key: "Sub-Total",
                          value: revenue.invoice_item_subtotal,
                        },
                        {
                          key: "Discount",
                          value: revenue.invoice_discount_amount,
                        },
                        {
                          key: "Total",
                          value: revenue.invoice_total,
                        },
                        {
                          key: "Paid",
                          value: revenue.invoice_paid,
                        },
                        {
                          key: "Balance",
                          value: revenue.invoice_balance,
                        },
                        {
                          key: "Payment Status",
                          value: getInvoiceStatusValue(
                            parseInt(revenue.invoice_status_id)
                          ),
                        },
                        {
                          key: "Payment Date",
                          value: revenue.payment_date,
                        },
                        {
                          key: "Payment Method",
                          value: revenue.payment_method_name,
                        },
                      ]}
                    />
                  ))}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

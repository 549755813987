import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../constants";

const navItems = [
  {
    label: "Dashboard",
    link: '/',
    icon: <DashboardIcon />,
  },
  {
    label: "Financial Report",
    link: RoutePaths.FINANCIAL_REPORT,
    icon: <LocalAtmIcon />,
  },
  {
    label: "Orders",
    link: RoutePaths.ORDER_LISTING,
    icon: <ShoppingCartIcon />,
  },
  {
    label: "Customers",
    link: RoutePaths.SEARCH_CUSTOMER,
    icon: <PeopleIcon />,
  },
  {
    label: "Complaints",
    link: RoutePaths.COMPLAINT_LISTING,
    icon: <PendingActionsIcon />,
  },
  {
    label: "Configurations",
    link: RoutePaths.CONFIGURATION,
    icon: <SettingsIcon />,
  },
]

export const DrawerNavigationList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <React.Fragment>
      {navItems.map((item) => (
            <ListItemButton
              key={item.label}
              selected={pathname === item.link}
              onClick={() => {
                navigate(item.link);
              }}
              sx={{
                "&.Mui-selected": {
                  color: "white",
                  backgroundColor: "#10b9b2",
                  textWrap:'wrap',
                  ":hover":{
                    backgroundColor:"#87d9d6"
                  }
                },
              }}
            >
              <ListItemIcon
                sx={{ color: pathname === item.link ? "white" : "" }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          
      ))}
      {/* Timesheet Listing  */}
    </React.Fragment>
    </React.Fragment>
  );
};

import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants";

export const CustomersTableActionMenu = ({ closeMenu, row }) => {
  const navigate = useNavigate();

  return [
    <MenuItem
      key={0}
      onClick={() => {
        closeMenu();
        navigate(RoutePaths.CREATE_ORDER, { state: row.original });
      }}
      sx={{ m: 0 }}
    >
      Create Order
    </MenuItem>,
    <MenuItem
      key={1}
      onClick={() => {
        closeMenu();
        navigate(`${RoutePaths.UPDATE_CUSTOMER}/${row.original.id}`);
      }}
      sx={{ m: 0 }}
    >
      Update Customer
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        closeMenu();
        navigate(`${RoutePaths.CUSTOMER_DETAILS}/${row.original.id}`);
      }}
      sx={{ m: 0 }}
    >
      Customer Details
    </MenuItem>,
  ];
};

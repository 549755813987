import { createSlice } from "@reduxjs/toolkit";

import {
  addServiceItemPricing,
  addUnitPrice,
  createItem,
  createService,
  disableItem,
  disableService,
  enableItem,
  enableService,
  fetchAllItems,
  fetchAllServices,
  fetchExpenseTypes,
  fetchItems,
  fetchServiceItemPrices,
  fetchServices,
  fetchUnitPriceItems,
} from "./thunks";
import { IConfigurationSlice } from "./types";

const initialState: IConfigurationSlice = {
  items: [],
  services: [],
  totalServices: [],
  totalItems: [],
  serviceItemPrices: [],
  expenseTypes: [],
  unitPriceItems: [],
  isLoading: false,
  isError: false,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchAllServices
    builder.addCase(fetchAllServices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalServices = action.payload;
    });
    builder.addCase(fetchAllServices.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchServices
    builder.addCase(fetchServices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.services = action.payload;
    });
    builder.addCase(fetchServices.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchItems
    builder.addCase(fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchItems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    });
    builder.addCase(fetchItems.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchAllItems
    builder.addCase(fetchAllItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllItems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalItems = action.payload;
    });
    builder.addCase(fetchAllItems.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // createItem
    builder.addCase(createItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createItem.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createItem.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // createService
    builder.addCase(createService.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createService.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createService.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchServiceItemPrices
    builder.addCase(fetchServiceItemPrices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchServiceItemPrices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceItemPrices = action.payload;
    });
    builder.addCase(fetchServiceItemPrices.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //addServiceItemPricing
    builder.addCase(addServiceItemPricing.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addServiceItemPricing.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addServiceItemPricing.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // disableService
    builder.addCase(disableService.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disableService.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(disableService.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // enableService
    builder.addCase(enableService.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(enableService.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(enableService.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // enableItem
    builder.addCase(enableItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(enableItem.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(enableItem.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // disableItem
    builder.addCase(disableItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disableItem.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(disableItem.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchExpenseTypes
    builder.addCase(fetchExpenseTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpenseTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.expenseTypes = action.payload;
    });
    builder.addCase(fetchExpenseTypes.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchUnitPriceItems
    builder.addCase(fetchUnitPriceItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnitPriceItems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.unitPriceItems = action.payload;
    });
    builder.addCase(fetchUnitPriceItems.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // addUnitPrice
    builder.addCase(addUnitPrice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUnitPrice.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addUnitPrice.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { reducer } = configurationSlice;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectConfiguration = createSelector(
  selectSelf,
  (state) => state.configuration
);

export const selectIsConfigurationLoading = createSelector(
  selectConfiguration,
  (configuration) => configuration.isLoading
);

export const selectTotalServices = createSelector(
  selectConfiguration,
  (configuration) => configuration.totalServices
);

export const selectTotalItems = createSelector(
  selectConfiguration,
  (configuration) => configuration.totalItems
);

export const selectServices = createSelector(
  selectConfiguration,
  (configuration) => configuration.services
);

export const selectItems = createSelector(
  selectConfiguration,
  (configuration) => configuration.items
);

export const selectServiceItemPrices = createSelector(
  selectConfiguration,
  (configuration) => configuration.serviceItemPrices
);


export const selectExpenseTypes = createSelector(
  selectConfiguration,
  (configuration) => configuration.expenseTypes
);


export const selectUnitPriceItems = createSelector(
  selectConfiguration,
  (configuration) => configuration.unitPriceItems
);


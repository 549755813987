import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Order } from "src/types/order/order";
import { RoutePaths } from "src/constants";
import MaterialReactTable from "material-react-table";
import { MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "src/store";
import { fetchOrdersPagination } from "src/slices/orders/thunks";
import { selectOrders } from "src/slices/orders/selectors";
import { OrderStatusChip } from "src/components/pages/order/order-status-chip";
import { CustomerOrderedServicesGrid } from "src/components/pages/order/customer-ordered-services-grid";
import { dayMonthYearFormat } from "src/lib/utils";
import { selectTotalOrders } from "src/slices/dashboard/selectors";
import { fetchTotalOrders } from "src/slices/dashboard/thunks";

export const OrdersList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  // const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const orders = useSelector(selectOrders);
  const totalOrders = useSelector(selectTotalOrders);

  const [columns] = useState<any>([
    { accessorKey: "orderId", header: "Order Id" },
    {
      accessorKey: "customerName",
      header: "Customer Name",
    },
    {
      accessorKey: "pickupDate",
      header: "Pickup Date",
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
    },
    {
      id: "orderStatus",
      accessorKey: "orderStatus",
      header: "Order Status",
      Cell: ({ cell }) => <OrderStatusChip orderStatus={cell.getValue()} />,
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(()=>{
    dispatch(fetchTotalOrders());
    // eslint-disable-next-line
  },[]);

  useEffect(()=>{
    setRowCount(totalOrders.reduce((total, val) => total + parseInt(val.totalorders), 0));
    // eslint-disable-next-line
  },[totalOrders]);

  useEffect(() => {
    // console.log(columnFilters, globalFilter)
    dispatch(fetchOrdersPagination({
      noOfRows: pagination.pageSize.toString(),
      pageNumber: (pagination.pageIndex  + 1).toString(),
      customerName: globalFilter || ''
    }));
    // dispatch(fetchOrders());
    // eslint-disable-next-line
  }, [pagination.pageSize, pagination.pageIndex, globalFilter]);

  useEffect(() => {
    const tableRows: any[] = orders.map((order: Order) => ({
      id: order.id,
      customerId: order.customerId,
      orderId: `LX-${order.id}`,
      customerName: order.customerName,
      pickupAddress: order.pickupAddress,
      deliveryAddress: order.deliveryAddress,
      createdAt: dayMonthYearFormat(new Date(order.orderDate), true),
      pickupDate: dayMonthYearFormat(new Date(order.pickUpDate)),
      dueDate: dayMonthYearFormat(new Date(order.dueDate)),
      orderStatus: order.orderStatus,
      servicesItems: order.servicesItems,
      invoiceNumber: order.invoicePlaneRefId,
    }));
    setRows(tableRows);
  }, [orders]);

  return (
    <Box sx={{ width: 1 }}>
      <MaterialReactTable
        onPaginationChange={setPagination}
        onGlobalFilterChange={setGlobalFilter}
        // onColumnFiltersChange={setColumnFilters}
        rowCount={rowCount}
        state={{
          pagination,
        }}
        manualPagination
        // manualFiltering
        columns={columns}
        data={rows}
        initialState={{
          density: "compact",
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
          showColumnFilters: state ? true : false,
          columnFilters: state
            ? [
                {
                  id: "orderStatus",
                  value: state.defaultOrderStatus,
                },
              ]
            : [],
        }}
        enableRowActions
        renderDetailPanel={({ row }) => (
          <CustomerOrderedServicesGrid rows={row.original.servicesItems} />
        )}
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              closeMenu();
              navigate(RoutePaths.UPDATE_ORDER, {
                state: { ...orders[row.index], isOrderUpdate: true },
              });
            }}
            sx={{ m: 0 }}
          >
            Order Update
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              closeMenu();
              navigate(`${RoutePaths.ORDER_DETAILS}/${row.original.id}`);
            }}
            sx={{ m: 0 }}
          >
            Order Details
          </MenuItem>,
        ]}
      />
    </Box>
  );
};

import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartCardProps {
  title: string;
  barChartList: BarChartProps[];
}

interface BarChartProps {
  xTitle: any;
  value: number;
}

export const BarChartCard: FC<BarChartCardProps> = ({
  title,
  barChartList,
}) => {
  const [data, setData] = useState(() => ({
    labels: barChartList.map((value) => value.xTitle),
    datasets: [
      {
        label: title,
        data: barChartList.map((value) => value.value),
        backgroundColor: "#10b9b2",
        barPercentage: 0.3, // Adjust this value to decrease the bar width
      },
    ],
  }));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: false,
        text: title,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        // ticks: {
        //   // forces step size to be 50 units
        //   stepSize: 50
        // }
      },
    },
  };

  useEffect(() => {
    setData({
      labels: barChartList.map((value) => value.xTitle),
      datasets: [
        {
          label: title,
          data: barChartList.map((value) => value.value),
          backgroundColor: "#10b9b2",
          barPercentage: 0.3, // Adjust this value to decrease the bar width
        },
      ],
    });
  }, [title, barChartList]);
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ alignItems: "center" }}>
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Bar options={options} data={data} height={"60%"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { Grid, Box, Checkbox, FormControlLabel } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { GENDER, RoutePaths } from "src/constants";
import { ContainedButton, OutlinedButton } from "src/components/button-group";
import { AppDispatch } from "src/store";
import { ICustomer } from "src/slices/customers/types";
import {
  createUpdateCustomer,
  fetchCustomerAddresses,
  fetchCustomerDetailsById,
} from "src/slices/customers/thunks";
import {
  selectCustomerAddresses,
  selectCustomerDetails,
} from "src/slices/customers/selectors";
import {
  emptyCustomerAddressesState,
  emptyCustomerDetailsState,
} from "src/slices/customers";
import {
  ICreateUpdateCustomerPayload,
  ICreateUpdateCustomerForm,
} from "../types";
export function CreateAndUpdateCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { customerId } = useParams();
  const customerDetails = useSelector(selectCustomerDetails);
  const customerAddresses = useSelector(selectCustomerAddresses);

  const formikRef = useRef(null);

  const [addressCols] = useState([
    { field: "address", headerName: "Address", width: 400, editable: false },
    {
      field: "googleLocation",
      headerName: "Google Location",
      width: 400,
      editable: false,
    },
  ]);
  const [addressRows, setAddressRows] = useState([]);
  const [addressSelectionModel, setAddressSelectionModel] =
    useState<GridSelectionModel>([]);

  // Form Inital Values
  const formInitialValue: ICreateUpdateCustomerForm = {
    customerFullName: "",
    gender: "",
    dateOfBirth: new Date(Date.now()),
    contactNumber: "",
    alternateNumber: "",
    emailAddress: "",
    address: "",
    addressLocation: "",
    isRecurring: false,
  };

  useEffect(() => {
    if (addressRows.length > 0) {
      formikRef.current.setFieldValue("pickupAddress", addressRows[0].id);
      formikRef.current.setFieldValue(
        "pickupLocation",
        addressRows[0].googleLocation ?? ""
      );
      formikRef.current.setFieldValue("deliveryAddress", addressRows[0].id);
      formikRef.current.setFieldValue(
        "deliveryLocation",
        addressRows[0].googleLocation ?? ""
      );
    }
  }, [addressRows]);

  // Form Fields Validation
  const validationSchemaModel = Yup.object().shape({
    contactNumber: Yup.string().required("You must insert a contact number"),
    address: Yup.string().test(
      "required Address",
      "Please add at least one address",
      (value) => addressRows.length > 0
    ),
  });

  // Click Events
  const onClickSubmit = async (values: ICreateUpdateCustomerForm) => {
    const customer = {
      customerId: customerId ? customerId : null,
      name: values.customerFullName.trim(),
      dob: format(values.dateOfBirth, "yyyy-MM-dd"),
      email: values.emailAddress.trim(),
      primaryNumber: values.contactNumber.trim(),
      secondaryNumber:
        values.alternateNumber.trim() === ""
          ? null
          : values.alternateNumber.trim(),
      gender: values.gender[0]?.toUpperCase(),
      isRecurring: values.isRecurring,
      addresses: addressRows.map((addressData) => ({
        address: addressData.address,
        googleLocation:
          addressData.googleLocation === "" ? null : addressData.googleLocation,
      })),
    } as ICreateUpdateCustomerPayload;

    dispatch(createUpdateCustomer(customer)).then((result: any) => {
      if (!result.payload.error) {
        navigate(RoutePaths.SEARCH_CUSTOMER);
      }
    });
  };

  // If Customer Update Screen
  useEffect(() => {
    if (customerId) {
      dispatch(fetchCustomerDetailsById(customerId));
      dispatch(fetchCustomerAddresses(customerId));
      return () => {
        dispatch(emptyCustomerDetailsState());
        dispatch(emptyCustomerAddressesState());
      };
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customerId) {
      if (customerDetails) {
        loadCustomerData(customerDetails);
      }
      if (customerAddresses) {
        setAddressRows(customerAddresses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails, customerAddresses]);

  const loadCustomerData = async (customer: ICustomer) => {
    const { setFieldValue } = formikRef.current;

    setFieldValue("customerFullName", customer.name);
    setFieldValue("gender", customer.gender ? GENDER[customer.gender] : "");
    setFieldValue("dateOfBirth", new Date(customer.dob));
    setFieldValue("contactNumber", customer.primaryNumber);
    setFieldValue("alternateNumber", customer.secondaryNumber ?? "");
    setFieldValue("emailAddress", customer.email ?? "");
    setFieldValue("isRecurring", customer.isRecurring);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          initialValues={formInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            onClickSubmit(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="CreateOrder">
              {/* Personal Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Personal Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="customerFullName"
                    label={"Customer Full Name"}
                    error={Boolean(
                      touched.customerFullName && errors.customerFullName
                    )}
                    helperText={
                      touched.customerFullName && errors.customerFullName
                    }
                    {...getFieldProps("customerFullName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    name="gender"
                    label="Gender"
                    options={[
                      {
                        label: "Male",
                        value: "Male",
                      },
                      {
                        label: "Female",
                        value: "Female",
                      },
                    ]}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    {...getFieldProps("gender")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Date of Birth"
                    dateOrTimeOnly="date"
                    name="dateOfBirth"
                    disableFuture
                    error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    {...getFieldProps("dateOfBirth")}
                  />
                </Grid>
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Contact Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="contactNumber"
                    label="Contact Number"
                    error={Boolean(
                      touched.contactNumber && errors.contactNumber
                    )}
                    helperText={touched.contactNumber && errors.contactNumber}
                    {...getFieldProps("contactNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="alternateNumber"
                    label="Alternate Number"
                    error={Boolean(
                      touched.alternateNumber && errors.alternateNumber
                    )}
                    helperText={
                      touched.alternateNumber && errors.alternateNumber
                    }
                    {...getFieldProps("alternateNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="emailAddress"
                    label="Email Address"
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    helperText={touched.emailAddress && errors.emailAddress}
                    {...getFieldProps("emailAddress")}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    control="InputField"
                    name="address"
                    label="Address"
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    {...getFieldProps("address")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="addressLocation"
                    label="Address Google Location"
                    error={Boolean(
                      touched.addressLocation && errors.addressLocation
                    )}
                    helperText={
                      touched.addressLocation && errors.addressLocation
                    }
                    {...getFieldProps("addressLocation")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <OutlinedButton
                        size="small"
                        onClick={() => {
                          if (values.address.length === 0) return;
                          setAddressRows((rows) => [
                            ...rows,
                            {
                              id: rows.length,
                              address: values.address,
                              googleLocation: values.addressLocation,
                            },
                          ]);
                          setFieldValue("address", "");
                          setFieldValue("addressLocation", "");
                        }}
                      >
                        Add Address
                      </OutlinedButton>
                    </Grid>

                    <Grid item>
                      <OutlinedButton
                        size="small"
                        disabled={addressSelectionModel.length <= 0}
                        onClick={() => {
                          setAddressRows(
                            addressRows.filter(
                              (item) => !addressSelectionModel.includes(item.id)
                            )
                          );
                        }}
                      >
                        Remove Address
                      </OutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ height: 200, width: 1 }}>
                    <DataGrid
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      hideFooter
                      checkboxSelection
                      onSelectionModelChange={(newSelectionModel) => {
                        setAddressSelectionModel(newSelectionModel);
                      }}
                      selectionModel={addressSelectionModel}
                      columns={addressCols}
                      rows={addressRows}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* Recurring Customer? */}
              <Grid container spacing={1} pb={3}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isRecurring}
                        name="isRecurring"
                        {...getFieldProps("isRecurring")}
                      />
                    }
                    label="Is Customer Recurring?"
                  />
                </Grid>
              </Grid>
              {/* SUBMIT FORM */}
              <Grid container spacing={1}>
                <Grid item>
                  <ContainedButton type="submit">SUBMIT</ContainedButton>
                </Grid>
                <Grid item>
                  <OutlinedButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    CANCEL
                  </OutlinedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

import { useNavigate } from "react-router-dom";
import { OutlinedButton } from "src/components/button-group";
import { RoutePaths } from "src/constants";

export const CustomersTableActionBar = () => {
  const navigate = useNavigate();
  return (
    <OutlinedButton onClick={() => navigate(RoutePaths.CREATE_CUSTOMER)}>
      Create Customer
    </OutlinedButton>
  );
};

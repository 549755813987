import { Chip, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { toastMessage } from "src/slices/commonSlice";
import { selectTotalServices } from "src/slices/configuration/selectors";
import {
  createService,
  disableService,
  enableService,
  fetchAllServices,
} from "src/slices/configuration/thunks";
import { IService } from "src/slices/configuration/types";
import { AppDispatch } from "src/store";

interface IformInitialValue {
  service: string;
}

export default function ServicePanel() {
  const dispatch = useDispatch<AppDispatch>();

  const formikRef = useRef(null);
  const services: IService[] = useSelector(selectTotalServices);

  // Form Inital Values
  const formInitialValue: IformInitialValue = {
    service: "",
  };

  // Click Events
  const onClickCreateService = async (serviceName: string) => {
    const service = serviceName.trim();
    if (service !== "") {
      const response = await dispatch(createService(service));
      if (response.payload) {
        dispatch(fetchAllServices());
        formikRef.current.setFieldValue("service", "");
      }
    } else {
      dispatch(
        toastMessage({
          error: true,
          message: "Service cannot be empty",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAllServices());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Formik
        initialValues={formInitialValue}
        innerRef={formikRef}
        onSubmit={() => {}}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form id="Configuration">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="InputField"
                      name="service"
                      label={"Service"}
                      error={Boolean(touched.service && errors.service)}
                      helperText={touched.service && errors.service}
                      {...getFieldProps("service")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ContainedButton
                      size="medium"
                      onClick={() => {
                        onClickCreateService(values.service);
                      }}
                    >
                      Add Service
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* ADD NEW SERVICE */}
                <MaterialReactTable
                  columns={[
                    {
                      accessorKey: "serviceName",
                      header: "Service Name",
                    },
                    {
                      accessorKey: "isActive",
                      header: "Active",
                      Cell: ({ cell }) => (
                        <Chip
                          label={cell.getValue() ? "YES" : "NO"}
                          variant="filled"
                          color={cell.getValue() ? "success" : "error"}
                          sx={{ minWidth: 50 }}
                        />
                      ),
                    },
                  ]}
                  data={services.map((service) => ({
                    id: service.id,
                    serviceName: service.name,
                    isActive: service.isActive,
                  }))}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 5, pageIndex: 0 },
                  }}
                  enableRowActions
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      key={2}
                      onClick={async () => {
                        closeMenu();
                        await dispatch(enableService(row.original.id));
                        dispatch(fetchAllServices());
                      }}
                      sx={{ m: 0 }}
                    >
                      Enable Service
                    </MenuItem>,
                    <MenuItem
                      key={1}
                      onClick={async () => {
                        closeMenu();
                        await dispatch(disableService(row.original.id));
                        dispatch(fetchAllServices());
                      }}
                      sx={{ m: 0 }}
                    >
                      Disable Service
                    </MenuItem>,
                  ]}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { FinancialDashboardAPIService } from "src/constants";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { IFetchRevenueByStatusPayload } from "./types";

export const fetchTotalSales = createAsyncThunk(
  "financialDashboard/fetchTotalSales",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        FinancialDashboardAPIService.GET_TOTAL_REVENUE,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchTotalInvoices = createAsyncThunk(
  "financialDashboard/fetchTotalInvoices",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        FinancialDashboardAPIService.GET_REVENUE_BY_PAYMENT,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchRevenueByStatus = createAsyncThunk(
  "financialDashboard/fetchRevenueByStatus",
  async (
    revenueByStatusPayload: IFetchRevenueByStatusPayload,
    { dispatch }
  ) => {
    try {
      const response = await sendRequestJSON(
        null,
        FinancialDashboardAPIService.GET_REVENUE_BY_STATUS +
          `?startDate=${revenueByStatusPayload.startDate}&endDate=${revenueByStatusPayload.endDate}`,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchPaymentMethodCount = createAsyncThunk(
  "financialDashboard/fetchPaymentMethodCount",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        FinancialDashboardAPIService.GET_PAYMENT_METHOD_COUNT,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchRecentInvoices = createAsyncThunk(
  "financialDashboard/fetchRecentInvoices",
  async (recentInvoicesPayload: IFetchRevenueByStatusPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        FinancialDashboardAPIService.GET_RECENT_INVOICES +
          `?startDate=${recentInvoicesPayload.startDate}&endDate=${recentInvoicesPayload.endDate}`,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const downloadReport = createAsyncThunk(
  "financialDashboard/downloadReport",
  async (recentInvoicesPayload: IFetchRevenueByStatusPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        FinancialDashboardAPIService.DOWNLOAD_REPORT +
          `?startDate=${recentInvoicesPayload.startDate}&endDate=${recentInvoicesPayload.endDate}`,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

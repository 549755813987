import { Grid, Box, Tooltip, IconButton, Link } from "@mui/material";
import { Form, Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { addDays, format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import {
  REGEX_IS_NUMBER,
  GENDER,
  RoutePaths,
  getInvoiceStatusValue,
} from "src/constants";
import { Utils } from "src/lib/utils";
import { TableCustomer } from "src/types/tableTypes";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ContainedButton, OutlinedButton } from "src/components/button-group";
import { AppDispatch } from "src/store";
import {
  selectItems,
  selectServiceItemPrices,
  selectServices,
  selectTotalServices,
  selectTotalItems,
} from "src/slices/configuration/selectors";
import {
  IItem,
  IService,
  IServiceItemPrice,
} from "src/slices/configuration/types";
import {
  fetchAllServices,
  fetchAllItems,
  fetchItems,
  fetchServiceItemPrices,
  fetchServices,
} from "src/slices/configuration/thunks";
import { fetchCustomerAddresses } from "src/slices/customers/thunks";
import { ICustomerAddress } from "src/slices/customers/types";
import { selectCustomerAddresses } from "src/slices/customers/selectors";
import { emptyCustomerAddressesState } from "src/slices/customers";
import { createUpdateOrder } from "src/slices/orders/thunks";
import CKeyValueList from "src/components/CKeyValueList";

interface IformInitialValue {
  invoiceNumber: string;
  customerFullName: string;
  gender: string;
  dateOfBirth: Date;
  contactNumber: string;
  alternateNumber: string;
  emailAddress: string;
  address: string;
  addressLocation: string;
  pickupAddress: string;
  pickupLocation: string;
  deliveryAddress: string;
  deliveryLocation: string;
  dueDate: Date;
  pickupDate: Date;
  servicesCustomerWants: string;
  servicesForItems: string;
  estimatedNumberOfItems: string;
  specialInstruction: string;
  orderStatus: string;
}

export function CreateAndUpdateOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const formikRef = useRef(null);
  const { state } = useLocation();

  const serviceItemPricing: IServiceItemPrice[] = useSelector(
    selectServiceItemPrices
  );
  const customerAddresses: ICustomerAddress[] = useSelector(
    selectCustomerAddresses
  );

  const allServices: IService[] = useSelector(selectTotalServices);
  const allItems: IItem[] = useSelector(selectTotalItems);

  const allActiveServices: IService[] = useSelector(selectServices);
  const allActiveItems: IItem[] = useSelector(selectItems);

  // Services
  const [columns] = useState([
    { field: "services", headerName: "Services", width: 300, editable: false },
    { field: "items", headerName: "Items", width: 300, editable: false },
    {
      field: "estimatedItems",
      headerName: "Estimated Items",
      width: 300,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 300,
      editable: false,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [servicesSelectionModel, setServicesSelectionModel] =
    useState<GridSelectionModel>([]);

  const [addressRows, setAddressRows] = useState([]);

  // Click Events
  const onClickSubmit = async (values: IformInitialValue) => {
    let order = {};
    if (state && state.isOrderUpdate) {
      order = {
        orderId: state.id,
        invoicePlaneRefId: values.invoiceNumber,
        customerId: state.customerId,
        pickupAddressGoogleLocation: values.pickupLocation ?? "",
        deliveryAddress: values.deliveryAddress,
        deliveryAddressGoogleLocation: values.deliveryLocation ?? "",
        pickupAddress: values.pickupAddress,
        dueDate: format(values.dueDate, "yyyy-MM-dd"),
        pickUpDate: format(values.pickupDate, "yyyy-MM-dd"),
        orderDate: new Date(Date.now()).toISOString(),
        orderStatus: values.orderStatus,
        isCancelled: null,
        itemAndServiceId: rows.map((service) => ({
          serviceId: service.serviceId,
          itemId: service.itemId,
          estimatedNumberOfItems: parseInt(service.estimatedItems),
        })),
        specialInstruction: values.specialInstruction,
      };
    } else {
      order = {
        orderId: null,
        invoicePlaneRefId: values.invoiceNumber,
        customerId: state.id,
        pickupAddressGoogleLocation: values.pickupLocation ?? "",
        deliveryAddress: values.deliveryAddress,
        deliveryAddressGoogleLocation: values.deliveryLocation ?? "",
        pickupAddress: values.pickupAddress,
        dueDate: format(values.dueDate, "yyyy-MM-dd"),
        pickUpDate: format(values.pickupDate, "yyyy-MM-dd"),
        orderDate: new Date(Date.now()).toISOString(),
        orderStatus: "1",
        isCancelled: null,
        itemAndServiceId: rows.map((service) => ({
          serviceId: service.serviceId,
          itemId: service.itemId,
          estimatedNumberOfItems: parseInt(service.estimatedItems),
        })),
        specialInstruction: values.specialInstruction,
      };
    }
    dispatch(createUpdateOrder(order)).then((result: any) => {
      if (!result.payload?.error) {
        navigate(RoutePaths.ORDER_LISTING);
      }
    });
  };
  const onClickAddService = (values: IformInitialValue) => {
    if (!REGEX_IS_NUMBER.test(values.estimatedNumberOfItems)) return;
    if (values.servicesCustomerWants.length === 0) return;
    if (values.estimatedNumberOfItems.length === 0) return;
    if (values.servicesForItems.length === 0) return;

    setRows((rows) => [
      ...rows,
      {
        id: rows.length,
        serviceId: values.servicesCustomerWants,
        itemId: values.servicesForItems,
        services: allActiveServices.filter(
          (service) => service.id === values.servicesCustomerWants
        )[0].name,
        items: allActiveItems.filter(
          (item) => item.id === values.servicesForItems
        )[0].name,
        estimatedItems: values.estimatedNumberOfItems,
        price:
          parseInt(values.estimatedNumberOfItems) *
          parseInt(
            serviceItemPricing.filter(
              (mapping) =>
                mapping.serviceId === values.servicesCustomerWants &&
                mapping.itemId === values.servicesForItems
            )[0]?.price
          ),
      },
    ]);
    formikRef.current.setFieldValue("servicesCustomerWants", "");
    formikRef.current.setFieldValue("servicesForItems", "");
    formikRef.current.setFieldValue("estimatedNumberOfItems", "");
  };
  const onClickRemoveService = () => {
    setRows(
      rows.filter((service) => !servicesSelectionModel.includes(service.id))
    );
  };

  // Inital Values
  const getDueDateInitDate = (pickupDate?:Date): Date => {
    const currentDate = pickupDate||new Date(Date.now());
    let newDate = addDays(currentDate, 3);
    const isSunday = Utils.isSundayBetween(
      currentDate,
      addDays(currentDate, 3)
    );
    if (isSunday) {
      newDate = addDays(currentDate, 4);
    }
    return newDate;
  };
  const formInitialValue: IformInitialValue = {
    invoiceNumber: "",
    customerFullName: "",
    gender: "",
    dateOfBirth: new Date(Date.now()),
    contactNumber: "",
    alternateNumber: "",
    emailAddress: "",
    address: "",
    addressLocation: "",
    pickupAddress: "",
    pickupLocation: "",
    deliveryAddress: "",
    deliveryLocation: "",
    dueDate: getDueDateInitDate(),
    pickupDate: new Date(Date.now()),
    servicesCustomerWants: "",
    servicesForItems: "",
    estimatedNumberOfItems: "",
    specialInstruction: "",
    orderStatus: "1",
  };
  const validationSchemaModel = Yup.object().shape({
    invoiceNumber: Yup.string().test(
      "input type restriction",
      "Only numbers are allowed",
      (value) => {
        return (
          REGEX_IS_NUMBER.test(value) || value === "" || value === undefined
        );
      }
    ),
    servicesCustomerWants: Yup.string().test(
      "required Service",
      "Please add at least one service",
      (value) => rows.length > 0
    ),
    dueDate: Yup.date().test(
      "greaterThanPickup",
      "Due date must be greater than or equal to pickup date",
      function (dueDate) {
        return dueDate?.getTime() >= this.parent.pickupDate?.getTime();
      }
    ),
  });
  useEffect(() => {
    if (state) {
      if (!state.isOrderUpdate) {
        // populate values
        const customerDetails: TableCustomer = state;
        formikRef.current.setFieldValue(
          "customerFullName",
          customerDetails.customerName
        );
        formikRef.current.setFieldValue(
          "gender",
          customerDetails.gender === "" ? "" : GENDER[customerDetails.gender]
        );
        formikRef.current.setFieldValue("emailAddress", customerDetails.email);
        formikRef.current.setFieldValue(
          "contactNumber",
          customerDetails.primaryNumber
        );
        formikRef.current.setFieldValue(
          "alternateNumber",
          customerDetails.secondaryNumber ?? ""
        );
        formikRef.current.setFieldValue(
          "dateOfBirth",
          customerDetails.dateOfBirth ?? new Date(Date.now())
        );

        // get data from APIs
        dispatch(fetchCustomerAddresses(customerDetails.id));
        dispatch(fetchAllServices());
        dispatch(fetchAllItems());
        dispatch(fetchServices());
        dispatch(fetchItems());
      } else {
        // populate values
        const customerAndOrderDetails = state;
        formikRef.current.setFieldValue(
          "customerFullName",
          customerAndOrderDetails.customerName
        );
        formikRef.current.setFieldValue(
          "gender",
          customerAndOrderDetails.gender === ""
            ? ""
            : GENDER[customerAndOrderDetails.customerGender]
        );
        formikRef.current.setFieldValue(
          "emailAddress",
          customerAndOrderDetails.customerEmail
        );
        formikRef.current.setFieldValue(
          "contactNumber",
          customerAndOrderDetails.customerPrimaryNumber
        );
        formikRef.current.setFieldValue(
          "alternateNumber",
          customerAndOrderDetails.customerSecondaryNumber ?? ""
        );
        formikRef.current.setFieldValue(
          "dateOfBirth",
          customerAndOrderDetails.customerDOB ?? new Date(Date.now())
        );
        formikRef.current.setFieldValue(
          "pickupDate",
          new Date(customerAndOrderDetails.pickUpDate)
        );
        formikRef.current.setFieldValue(
          "dueDate",
          new Date(customerAndOrderDetails.dueDate)
        );
        formikRef.current.setFieldValue(
          "specialInstruction",
          customerAndOrderDetails?.specialInstructions ?? ""
        );

        formikRef.current.setFieldValue(
          "invoiceNumber",
          customerAndOrderDetails?.invoicePlaneRefId ?? ""
        );

        formikRef.current.setFieldValue(
          "orderStatus",
          customerAndOrderDetails?.statusId
        );

        // get data from APIs
        dispatch(fetchCustomerAddresses(customerAndOrderDetails.customerId));
        dispatch(fetchAllServices());
        dispatch(fetchAllItems());
        dispatch(fetchServices());
        dispatch(fetchItems());
      }
      dispatch(fetchServiceItemPrices());
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (allItems.length && allServices.length && state && state.isOrderUpdate) {
      const servicesList = state.servicesItems.map((service, index) => {
        const serviceId = allServices.filter(
          (ser) => ser.name === service.service
        )[0].id;
        const itemId = allItems.filter((item) => item.name === service.item)[0]
          .id;
        let price =
          parseInt(service.estimatedNumOfItems) * parseInt(service.price);
        price = isNaN(price) ? null : price;
        return {
          id: index,
          serviceId: serviceId,
          items: service.item,
          services: service.service,
          estimatedItems: service.estimatedNumOfItems,
          itemId: itemId,
          price: price,
        };
      });
      setRows(servicesList);
    }
    // eslint-disable-next-line
  }, [allServices, allItems, state]);

  // onChange Events
  useEffect(() => {
    if (state && !state.isOrderUpdate) {
      if (addressRows.length > 0) {
        formikRef.current.setFieldValue(
          "pickupAddress",
          addressRows[0].address
        );
        formikRef.current.setFieldValue(
          "pickupLocation",
          addressRows[0].googleLocation ?? ""
        );
        formikRef.current.setFieldValue(
          "deliveryAddress",
          addressRows[0].address
        );
        formikRef.current.setFieldValue(
          "deliveryLocation",
          addressRows[0].googleLocation ?? ""
        );
      }
    } else if (state && state.isOrderUpdate) {
      formikRef.current.setFieldValue("pickupAddress", state.pickupAddress);
      formikRef.current.setFieldValue(
        "pickupLocation",
        state.pickupAddressGoogleLocation
      );
      formikRef.current.setFieldValue("deliveryAddress", state.deliveryAddress);
      formikRef.current.setFieldValue(
        "deliveryLocation",
        state.deliveryAddressGoogleLocation
      );
    }
  }, [addressRows, state]);

  useEffect(() => {
    if (customerAddresses) {
      setAddressRows(customerAddresses);
    }
    return () => {
      dispatch(emptyCustomerAddressesState());
    };
    // eslint-disable-next-line
  }, [customerAddresses]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          initialValues={formInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            onClickSubmit(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="CreateOrder">
              {/* Personal Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Personal Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    disabled
                    name="customerFullName"
                    label={"Customer Full Name"}
                    error={Boolean(
                      touched.customerFullName && errors.customerFullName
                    )}
                    helperText={
                      touched.customerFullName && errors.customerFullName
                    }
                    {...getFieldProps("customerFullName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    name="gender"
                    label="Gender"
                    disabled
                    options={[
                      {
                        label: "Male",
                        value: "Male",
                      },
                      {
                        label: "Female",
                        value: "Female",
                      },
                    ]}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    {...getFieldProps("gender")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Date of Birth"
                    dateOrTimeOnly="date"
                    name="dateOfBirth"
                    disabled
                    error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    {...getFieldProps("dateOfBirth")}
                  />
                </Grid>
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Contact Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="contactNumber"
                    label="Contact Number"
                    disabled
                    error={Boolean(
                      touched.contactNumber && errors.contactNumber
                    )}
                    helperText={touched.contactNumber && errors.contactNumber}
                    {...getFieldProps("contactNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="alternateNumber"
                    label="Alternate Number"
                    disabled
                    error={Boolean(
                      touched.alternateNumber && errors.alternateNumber
                    )}
                    helperText={
                      touched.alternateNumber && errors.alternateNumber
                    }
                    {...getFieldProps("alternateNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="emailAddress"
                    label="Email Address"
                    disabled
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    helperText={touched.emailAddress && errors.emailAddress}
                    {...getFieldProps("emailAddress")}
                  />
                </Grid>
              </Grid>

              {/* Invoice */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">Invoice</CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="invoiceNumber"
                    label={"Invoice#"}
                    error={Boolean(
                      touched.invoiceNumber && errors.invoiceNumber
                    )}
                    helperText={touched.invoiceNumber && errors.invoiceNumber}
                    {...getFieldProps("invoiceNumber")}
                  />
                </Grid>
              </Grid>

              {/* Pickup and Delivery Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Pickup and Delivery Information
                  </CTypography>
                </Grid>

                {/* Pickup */}
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    control="SelectField"
                    name="pickupAddress"
                    label="Pickup Address"
                    options={addressRows.map((addressData) => ({
                      value: addressData.address,
                      label: addressData.address,
                    }))}
                    handleChange={(e: any) => {
                      setFieldValue("pickupAddress", e.target.value);
                      setFieldValue(
                        "pickupLocation",
                        addressRows.filter(
                          (addressData) =>
                            addressData.address === e.target.value
                        )[0].googleLocation ?? ""
                      );
                    }}
                    error={Boolean(
                      touched.pickupAddress && errors.pickupAddress
                    )}
                    helperText={touched.pickupAddress && errors.pickupAddress}
                    {...getFieldProps("pickupAddress")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <FormikControl
                        control="InputField"
                        name="pickupLocation"
                        label="Pickup Google Location"
                        disabled
                        error={Boolean(
                          touched.pickupLocation && errors.pickupLocation
                        )}
                        helperText={
                          touched.pickupLocation && errors.pickupLocation
                        }
                        {...getFieldProps("pickupLocation")}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Copy">
                        <IconButton
                          color="inherit"
                          disabled={values.pickupLocation === ""}
                          onClick={() =>
                            navigator.clipboard.writeText(values.pickupLocation)
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Delivery */}
                <Grid item xs={12} sm={8}>
                  <FormikControl
                    control="SelectField"
                    name="deliveryAddress"
                    label="Delivery Address"
                    options={addressRows.map((addressData) => ({
                      value: addressData.address,
                      label: addressData.address,
                    }))}
                    handleChange={(e: any) => {
                      setFieldValue("deliveryAddress", e.target.value);
                      setFieldValue(
                        "deliveryLocation",
                        addressRows.filter(
                          (addressData) =>
                            addressData.address === e.target.value
                        )[0].googleLocation ?? ""
                      );
                    }}
                    error={Boolean(
                      touched.deliveryAddress && errors.deliveryAddress
                    )}
                    helperText={
                      touched.deliveryAddress && errors.deliveryAddress
                    }
                    {...getFieldProps("deliveryAddress")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <FormikControl
                        control="InputField"
                        name="deliveryLocation"
                        label="Delivery Google Location"
                        disabled
                        error={Boolean(
                          touched.deliveryLocation && errors.deliveryLocation
                        )}
                        helperText={
                          touched.deliveryLocation && errors.deliveryLocation
                        }
                        {...getFieldProps("deliveryLocation")}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Copy">
                        <IconButton
                          color="inherit"
                          disabled={values.deliveryLocation === ""}
                          onClick={() =>
                            navigator.clipboard.writeText(
                              values.deliveryLocation
                            )
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Pickup Date"
                    dateOrTimeOnly="date"
                    name="pickupDate"
                    handleChange={(newValue) => {
                      setFieldValue('pickupDate', newValue)
                      setFieldValue('dueDate', getDueDateInitDate(newValue))
                    }}
                    error={Boolean(touched.pickupDate && errors.pickupDate)}
                    helperText={touched.pickupDate && errors.pickupDate}
                    {...getFieldProps("pickupDate")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Due Date"
                    dateOrTimeOnly="date"
                    name="dueDate"
                    error={Boolean(touched.dueDate && errors.dueDate)}
                    helperText={touched.dueDate && errors.dueDate}
                    {...getFieldProps("dueDate")}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="specialInstruction"
                    label="Special Instruction"
                    error={Boolean(
                      touched.specialInstruction && errors.specialInstruction
                    )}
                    helperText={
                      touched.specialInstruction && errors.specialInstruction
                    }
                    {...getFieldProps("specialInstruction")}
                  />
                </Grid>
              </Grid>

              {/* Order Status */}
              {state && state.isOrderUpdate && (
                <Grid container spacing={1} pb={3}>
                  <Grid item xs={12}>
                    <CTypography fontWeight="bold">Order Status</CTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="SelectField"
                      name="orderStatus"
                      label="Order Status"
                      options={[
                        {
                          label: "New Order",
                          value: "1",
                        },
                        {
                          label: "Ready for Pickup",
                          value: "2",
                        },
                        {
                          label: "In Progress",
                          value: "3",
                        },
                        {
                          label: "Ready for Delivery",
                          value: "4",
                        },
                        {
                          label: "Delivered",
                          value: "5",
                        },
                        {
                          label: "Pickup Failed",
                          value: "6",
                        },
                        {
                          label: "Delivery Failed",
                          value: "7",
                        },
                        {
                          label: "Cancelled",
                          value: "8",
                        },
                      ]}
                      error={Boolean(touched.orderStatus && errors.orderStatus)}
                      helperText={touched.orderStatus && errors.orderStatus}
                      {...getFieldProps("orderStatus")}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Order Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">Order Information</CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    name="servicesCustomerWants"
                    label="Services Customer Wants"
                    options={allActiveServices.map((service) => ({
                      value: service.id,
                      label: service.name,
                    }))}
                    handleChange={(e: any) => {
                      setFieldValue("servicesCustomerWants", e.target.value);
                      setFieldValue("servicesForItems", "");
                    }}
                    error={Boolean(
                      touched.servicesCustomerWants &&
                        errors.servicesCustomerWants
                    )}
                    helperText={
                      touched.servicesCustomerWants &&
                      errors.servicesCustomerWants
                    }
                    {...getFieldProps("servicesCustomerWants")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    name="servicesForItems"
                    label="Items"
                    options={serviceItemPricing
                      .filter(
                        (mapping) =>
                          mapping.serviceId === values.servicesCustomerWants
                      )
                      .map((filteredItems) => ({
                        value: filteredItems.itemId,
                        label: filteredItems.itemName,
                      }))}
                    error={Boolean(
                      touched.servicesForItems && errors.servicesForItems
                    )}
                    helperText={
                      touched.servicesForItems && errors.servicesForItems
                    }
                    {...getFieldProps("servicesForItems")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="estimatedNumberOfItems"
                    label="Estimated Number of Items"
                    error={Boolean(
                      touched.estimatedNumberOfItems &&
                        errors.estimatedNumberOfItems
                    )}
                    helperText={
                      touched.estimatedNumberOfItems &&
                      errors.estimatedNumberOfItems
                    }
                    {...getFieldProps("estimatedNumberOfItems")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <OutlinedButton
                        size="small"
                        onClick={() => {
                          onClickAddService(values);
                        }}
                      >
                        Add Service
                      </OutlinedButton>
                    </Grid>
                    <Grid item>
                      <OutlinedButton
                        size="small"
                        disabled={servicesSelectionModel.length <= 0}
                        onClick={() => {
                          onClickRemoveService();
                        }}
                      >
                        Remove Service
                      </OutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9}>
                  <Box sx={{ height: 300, width: 1 }}>
                    <DataGrid
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      hideFooter
                      checkboxSelection
                      onSelectionModelChange={(newSelectionModel) => {
                        setServicesSelectionModel(newSelectionModel);
                      }}
                      selectionModel={servicesSelectionModel}
                      columns={columns}
                      rows={rows}
                    />
                  </Box>
                </Grid>
                {state.revenueDetails && (
                  <Grid item xs={12} sm={5} md={4} lg={3}>
                    {state.revenueDetails?.map((revenue) => (
                      <CKeyValueList
                        labelWidth={150}
                        size="small"
                        list={[
                          {
                            key: "Invoice Date",
                            value: revenue.invoice_date_created,
                          },
                          {
                            key: "Due Date",
                            value: revenue.invoice_date_due,
                          },
                          {
                            key: "Invoice URL",
                            value: (
                              <Link
                                href={revenue.invoice_url_key}
                                target="_blank"
                              >
                                <PictureAsPdfIcon />
                              </Link>
                            ),
                          },
                          {
                            key: "Sub-Total",
                            value: revenue.invoice_item_subtotal,
                          },
                          {
                            key: "Discount",
                            value: revenue.invoice_discount_amount,
                          },
                          {
                            key: "Total",
                            value: revenue.invoice_total,
                          },
                          {
                            key: "Paid",
                            value: revenue.invoice_paid,
                          },
                          {
                            key: "Balance",
                            value: revenue.invoice_balance,
                          },
                          {
                            key: "Payment Status",
                            value: getInvoiceStatusValue(
                              parseInt(revenue.invoice_status_id)
                            ),
                          },
                          {
                            key: "Payment Date",
                            value: revenue.payment_date,
                          },
                          {
                            key: "Payment Method",
                            value: revenue.payment_method_name,
                          },
                        ]}
                      />
                    ))}
                  </Grid>
                )}
              </Grid>

              {/* SUBMIT FORM */}
              <Grid container spacing={1}>
                <Grid item>
                  <ContainedButton type="submit">SUBMIT</ContainedButton>
                </Grid>
                <Grid item>
                  <OutlinedButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    CANCEL
                  </OutlinedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Order } from "src/types/order/order";
import { RoutePaths } from "src/constants";
import MaterialReactTable from "material-react-table";
import { Grid, MenuItem } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { InfoCard } from "src/components/info-cards/info-card";
import { AppDispatch } from "src/store";
import {
  fetchOrderCountByCustomerId,
  fetchOrdersByCustomerId,
} from "src/slices/orders/thunks";
import { selectSelectedCustomerOrders } from "src/slices/orders/selectors";
import { OrderStatusChip } from "src/components/pages/order/order-status-chip";
import { CustomerOrderedServicesGrid } from "src/components/pages/order/customer-ordered-services-grid";
import { dayMonthYearFormat } from "src/lib/utils";

export function CustomerOrders() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const orders: Order[] = useSelector(selectSelectedCustomerOrders);

  const [columns] = useState<any>([
    { accessorKey: "orderId", header: "Order Id" },
    {
      accessorKey: "customerName",
      header: "Customer Name",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
    },
    {
      accessorKey: "pickUpDate",
      header: "Pickup Date",
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
    },
    {
      accessorKey: "orderStatus",
      header: "Order Status",
      Cell: ({ cell }) => <OrderStatusChip orderStatus={cell.getValue()} />,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [lastOrderPlacedAt, setLastOrderPlacedAt] = useState("");

  useEffect(() => {
    dispatch(fetchOrdersByCustomerId(customerId));
    dispatch(fetchOrderCountByCustomerId(customerId)).then((response) => {
      if (response.payload) {
        setTotalOrderCount(response.payload.count);
        setLastOrderPlacedAt(
          new Date(response.payload.lastOrderedDate).toDateString()
        );
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tableRows: any[] = orders?.map((order) => ({
      id: order.id,
      customerId: order.customerId,
      orderId: `LX-${order.id}`,
      customerName: order.customerName,
      pickupAddress: order.pickupAddress,
      deliveryAddress: order.deliveryAddress,
      createdAt: dayMonthYearFormat(new Date(order.orderDate), true),
      orderStatus: order.orderStatus,
      isCancelled: order.isCancelled.toString(),
      servicesItems: order.servicesItems,
      pickUpDate: dayMonthYearFormat(new Date(order.pickUpDate)),
      dueDate: dayMonthYearFormat(new Date(order.dueDate)),
    }));
    setRows(tableRows);
  }, [orders]);

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard title="TOTAL ORDERS" value={totalOrderCount} />
        </Grid>
        {orders && orders.length > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard title="LAST ORDER PLACED AT" value={lastOrderPlacedAt} />
          </Grid>
        )}

        <Grid item xs={12}>
          <MaterialReactTable
            columns={columns}
            data={rows}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            enableRowActions
            renderDetailPanel={({ row }) => (
              <>
                <Grid container spacing={3}>
                  <Grid item>
                    <CustomerOrderedServicesGrid
                      rows={row.original.servicesItems}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                key={0}
                onClick={() => {
                  closeMenu();
                  navigate(RoutePaths.UPDATE_ORDER, {
                    state: { ...orders[row.index], isOrderUpdate: true },
                  });
                }}
                sx={{ m: 0 }}
              >
                Order Update
              </MenuItem>,
              <MenuItem
                key={1}
                onClick={() => {
                  closeMenu();
                  navigate(`${RoutePaths.ORDER_DETAILS}/${row.original.id}`);
                }}
                sx={{ m: 0 }}
              >
                Order Details
              </MenuItem>,
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

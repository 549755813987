import { Chip, useTheme } from "@mui/material";

export const OrderStatusChip = ({ orderStatus }) => {
  const theme = useTheme();

  function getOrderStatusStyle(status) {
    switch (status) {
      case "NEW ORDER":
        return {
          backgroundColor: theme.palette.orderStatus.newOrder.main,
          color: theme.palette.orderStatus.newOrder.contrastText,
        };
      case "READY FOR PICKUP":
        return {
          backgroundColor: theme.palette.orderStatus.readyForPickup.main,
          color: theme.palette.orderStatus.readyForPickup.contrastText,
        };
      case "IN PROGRESS":
        return {
          backgroundColor: theme.palette.orderStatus.inProgress.main,
          color: theme.palette.orderStatus.inProgress.contrastText,
        };
      case "READY FOR DELIVERY":
        return {
          backgroundColor: theme.palette.orderStatus.readyForDelivery.main,
          color: theme.palette.orderStatus.readyForDelivery.contrastText,
        };
      case "DELIVERED":
        return {
          backgroundColor: theme.palette.orderStatus.delivered.main,
          color: theme.palette.orderStatus.delivered.contrastText,
        };
      case "CANCELLED":
        return {
          backgroundColor: theme.palette.orderStatus.cancelled.main,
          color: theme.palette.orderStatus.cancelled.contrastText,
        };
      case "PICKUP FAILED":
        return {
          backgroundColor: theme.palette.orderStatus.cancelled.main,
          color: theme.palette.orderStatus.cancelled.contrastText,
        };
      case "DELIVERY FAILED":
        return {
          backgroundColor: theme.palette.orderStatus.cancelled.main,
          color: theme.palette.orderStatus.cancelled.contrastText,
        };
      default:
        return {
          backgroundColor: "#000",
          color: "#fff",
        };
    }
  }
  const statusStyle = getOrderStatusStyle(orderStatus);

  return <Chip label={orderStatus} variant="filled" sx={statusStyle} />;
};

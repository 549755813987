import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrderAPIService } from "src/constants";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import {
  IFetchFilteredOrdersPayload,
  IFetchFilteredPaginatedOrdersPayload,
} from "./types";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        OrderAPIService.GET_ORDERS_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchOrdersPagination = createAsyncThunk(
  "orders/fetchOrdersPagination",
  async (payload: IFetchFilteredPaginatedOrdersPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        OrderAPIService.GET_ORDERS_LIST_PAGINATION +
          `?pageNumber=${payload.pageNumber}&noOfRows=${payload.noOfRows}&customerName=${payload.customerName}`,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchOrderIdsByCustomerId = createAsyncThunk(
  "orders/fetchOrderIdsByCustomerId",
  async (customerId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          customerId,
        },
        OrderAPIService.GET_ORDER_IDS,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchOrdersByCustomerId = createAsyncThunk(
  "orders/fetchOrdersByCustomerId",
  async (customerId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          customerId,
        },
        OrderAPIService.GET_ORDER_BY_CUSTOMER_ID,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchOrderCountByCustomerId = createAsyncThunk(
  "orders/fetchOrderCountByCustomerId",
  async (customerId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          customerId,
        },
        OrderAPIService.GET_ORDER_COUNT_BY_CUSTOMER_ID,
        "post"
      );
      if (!response.error) {
        const { data } = response.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result?.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchOrderDetailsById = createAsyncThunk(
  "orders/fetchOrderDetailsById",
  async (orderId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          orderId,
        },
        OrderAPIService.GET_ORDER_DETAILS,
        "post"
      );
      if (!response.error) {
        const { data } = response.result?.data;
        return data[0];
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return response.result?.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const createUpdateOrder = createAsyncThunk(
  "orders/createUpdateOrder",
  async (order: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        order,
        OrderAPIService.CREATE_UPDATE_ORDER,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchFilteredOrders = createAsyncThunk(
  "orders/fetchFilteredOrders",
  async (filteredOrdersPayload: IFetchFilteredOrdersPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          startDate: filteredOrdersPayload.startDate,
          endDate: filteredOrdersPayload.endDate,
        },
        OrderAPIService.GET_FILTERED_ORDERS,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

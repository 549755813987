import { FC, useState } from "react";
import {
  BaseTextFieldProps,
  experimentalStyled,
  FormControl,
  TextField,
} from "@mui/material";
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { useTranslation } from 'react-i18next';
// import { BaseTextFieldProps } from '@material-ui/core/TextField/TextField';
// import DatePicker from '@material-ui/lab/DatePicker';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import DateTimePicker from '@material-ui/lab/DateTimePicker';
// import TimePicker from '@material-ui/lab/TimePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";

import "./CalendarTImeDate.styles.css";

export const CalendarTimeDateComponent = experimentalStyled(DateTimePicker)(
  () => ({
    "& .MuiTabs-root": {
      backgroundColor: "white",
    },
  })
);

interface CalendarTimeDateProps extends BaseTextFieldProps {
  name: string;
  label: string;
  helperText?: any;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  cancelText?: string;
  clearable?: boolean;
  clearText?: string;
  okText?: string;
  minutesStep?: number;
  dateOrTimeOnly?: string;
  readOnly?: boolean;
  disabled?: boolean;
  views?: Array<"day" | "month" | "year">;
  minDateTime?: Date;
  maxDateTime?: Date;
  disableFuture?: boolean;
  disablePast?: boolean;
  handleChange?: any
}

const CalendarTimeDate: FC<CalendarTimeDateProps> = (props) => {
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "small",
    fullWidth = true,
    variant = "outlined",
    required = false,
    // cancelText = 'Cancel',
    // clearable = true,
    // clearText = 'Clear',
    // okText = 'Ok',
    dateOrTimeOnly = "",
    readOnly = false,
    disabled = false,
    views = ["day", "month", "year"],
    minDateTime,
    maxDateTime,
    disableFuture = false,
    disablePast = false,
    handleChange
  } = props;

  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name });
  const [openDateTime, setOpenDateTime] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [openTime, setOpenTime] = useState(false);

  const change = (newValue) => {
    if(handleChange) handleChange(newValue)
    else setFieldValue(field.name, newValue);
  }

  switch (dateOrTimeOnly) {
    case "date":
      return (
        <FormControl fullWidth={fullWidth}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={(field.value && new Date(field.value)) || null}
              onChange={(newValue) => {
                change(newValue)
              }}
              label={label}
              open={openDate}
              disabled={disabled}
              readOnly={readOnly}
              inputFormat="dd/MM/yyyy"
              disableFuture={disableFuture}
              disablePast={disablePast}
              onOpen={() => setOpenDate(true)}
              onClose={() => setOpenDate(false)}
              views={views}
              // clearable={clearable}
              // clearText={clearText}
              // okText={okText}
              renderInput={(inputProps) => (
                <TextField
                  name={name}
                  size={size}
                  variant={variant}
                  required={required}
                  // placeholder={t(placeholder)}
                  helperText={helperText}
                  label={label}
                  onClick={() => !disabled && setOpenDate(true)}
                  {...inputProps}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );

    case "time":
      return (
        <FormControl fullWidth={fullWidth}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={(field.value && new Date(field.value)) || null}
              onChange={(newValue) => {
                change(newValue)
              }}
              label={label}
              open={openTime}
              // clearable={clearable}
              // clearText={clearText}
              disabled={disabled}
              // okText={okText}
              onOpen={() => setOpenTime(true)}
              onClose={() => setOpenTime(false)}
              renderInput={(inputProps) => (
                <TextField
                  name={name}
                  size={size}
                  variant={variant}
                  required={required}
                  placeholder={placeholder}
                  helperText={helperText}
                  label={label}
                  onClick={() => setOpenTime(true)}
                  {...inputProps}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );
    default:
      return (
        <FormControl fullWidth={fullWidth}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarTimeDateComponent
              value={(field.value && new Date(field.value)) || null}
              onChange={(newValue) => {
                change(newValue)
              }}
              minDateTime={minDateTime}
              maxDateTime={maxDateTime}
              label={label}
              inputFormat="dd/MM/yyyy"
              // cancelText={cancelText}
              // clearable={clearable}
              // clearText={clearText}
              disabled={disabled}
              // okText={okText}
              readOnly={readOnly}
              open={openDateTime}
              onOpen={() => setOpenDateTime(true)}
              onClose={() => setOpenDateTime(false)}
              renderInput={(inputProps) => (
                <TextField
                  name={name}
                  size={size}
                  variant={variant}
                  required={required}
                  helperText={helperText}
                  label={label}
                  onClick={() => {
                    if (!disabled) {
                      setOpenDateTime(true);
                    }
                  }}
                  {...inputProps}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );
  }
};

CalendarTimeDate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  size: PropTypes.any,
  variant: PropTypes.any,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  cancelText: PropTypes.string,
  clearable: PropTypes.bool,
  clearText: PropTypes.string,
  okText: PropTypes.string,
  minutesStep: PropTypes.number,
  readOnly: PropTypes.bool,
  views: PropTypes.any,
  minDateTime: PropTypes.any,
  maxDateTime: PropTypes.any,
};
export default CalendarTimeDate;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectOrder = createSelector(selectSelf, (state) => state.order);

export const selectIsOrderLoading = createSelector(
  selectOrder,
  (order) => order.isLoading
);

export const selectOrders = createSelector(
  selectOrder,
  (order) => order.orders
);

export const selectSelectedCustomerOrders = createSelector(
  selectOrder,
  (order) => order.selectedCustomerOrders
);

export const selectOrderIds = createSelector(
  selectOrder,
  (order) => order.orderIds
);

export const selectSelectedOrderDetails = createSelector(
  selectOrder,
  (order) => order.selectedOrderDetails
);

export const selectFilteredOrders = createSelector(
  selectOrder,
  (order) => order.filteredOrders
);

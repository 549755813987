import { createSlice } from "@reduxjs/toolkit";

import {
  createUpdateCustomer,
  fetchCustomerAddresses,
  fetchCustomerDetailsById,
  fetchCustomerIds,
  fetchCustomers,
} from "./thunks";
import { ICustomerSlice } from "./types";

export const customerSlice = createSlice({
  name: "customers",
  initialState: {
    customerDetails: null,
    customerAddresses: null,
    customerIds: [],
  } as ICustomerSlice,
  reducers: {
    emptyCustomerDetailsState: (state) => {
      state.customerDetails = null;
    },
    emptyCustomerAddressesState: (state) => {
      state.customerAddresses = null;
    },
  },
  extraReducers: (builder) => {
    // fetchCustomers
    builder.addCase(fetchCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = action.payload;
    });
    builder.addCase(fetchCustomers.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchCustomerIds
    builder.addCase(fetchCustomerIds.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomerIds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerIds = action.payload;
    });
    builder.addCase(fetchCustomerIds.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchCustomerDetailsById
    builder.addCase(fetchCustomerDetailsById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomerDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerDetails = action.payload;
    });
    builder.addCase(fetchCustomerDetailsById.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchCustomerAddresses
    builder.addCase(fetchCustomerAddresses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomerAddresses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerAddresses = action.payload;
    });
    builder.addCase(fetchCustomerAddresses.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // createUpdateCustomer
    builder.addCase(createUpdateCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUpdateCustomer.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createUpdateCustomer.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { emptyCustomerDetailsState, emptyCustomerAddressesState } =
  customerSlice.actions;
export const { reducer } = customerSlice;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerAPIService, DashboardAPIService } from "src/constants";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";

export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        DashboardAPIService.GET_DASHBOARD_DATA,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchTotalOrders = createAsyncThunk(
  "dashboard/fetchTotalOrders",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        DashboardAPIService.GET_TOTAL_ORDERS,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchTotalComplaints = createAsyncThunk(
  "dashboard/fetchTotalComplaints",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        DashboardAPIService.GET_TOTAL_COMPLAINTS,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchTotalOrdersByStatus = createAsyncThunk(
  "dashboard/fetchTotalOrdersByStatus",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        DashboardAPIService.GET_TOTAL_ORDERS_BY_STATUS,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchTotalOrdersByServices = createAsyncThunk(
  "dashboard/fetchTotalOrdersByServices",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        DashboardAPIService.GET_TOTAL_ORDERS_BY_SERVICES,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchTotalCustomers = createAsyncThunk(
  "dashboard/fetchTotalCustomersCount",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        CustomerAPIService.GET_CUSTOMER_COUNTS,
        "get"
      );
      if (response && !response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);


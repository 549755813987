import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectComplaint = createSelector(selectSelf, (state) => state.complaint);

export const selectIsComplaintLoading = createSelector(
  selectComplaint,
  (complaint) => complaint.isLoading
);

export const selectComplaints = createSelector(
  selectComplaint,
  (complaint) => complaint.complaints
);

export const selectCustomerComplaints = createSelector(
  selectComplaint,
  (complaint) => complaint.customerComplaints
);

export const selectSelectedComplaint = createSelector(
  selectComplaint,
  (complaint) => complaint.selectedComplaint
);

export const selectComplaintCategories = createSelector(
  selectComplaint,
  (complaint) => complaint.complaintCategories
);

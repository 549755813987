import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { styled } from '@mui/system';

const TypographyStyledComponent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

interface KeyValueProps {
  key: string | number;
  value: string | number;
  onClick?: () => void;
}
interface KeyValueListCardProps {
  title: string;
  keyValueList: Array<KeyValueProps>;
}

export const KeyValueListCard: FC<KeyValueListCardProps> = ({
  title,
  keyValueList,
}) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom variant="overline">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ p: 2, alignItems: "center", textAlign: "center" }}
        >
          {keyValueList.map((data) => (
            <>
              <Grid item xs={6}>
                <TypographyStyledComponent
                  onClick={data.onClick ? data.onClick : () => {}}
                  color="text"
                  gutterBottom
                  style={{
                    cursor: 'default'
                  }}
                  sx={{ "&:hover": { color: "primary" } }}
                >
                  {data.key}
                </TypographyStyledComponent>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text" gutterBottom>
                  {data.value}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialReactTable from "material-react-table";
import { Button, Chip, Grid, Link } from "@mui/material";
import { AppDispatch } from "src/store";
import { selectDownloadedReports } from "src/slices/financial-dashboard/selector";
import { downloadReport } from "src/slices/financial-dashboard/thunks";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { IInvoiceReport } from "src/slices/financial-dashboard/types";
import { format } from "date-fns";
import { Formik } from "formik";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";

export const ReportPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const reports = useSelector(selectDownloadedReports);
  const [columns] = useState<any>([
    { accessorKey: "invoice_number", header: "Invoice Number", size: 80 },
    {
      accessorKey: "invoice_date_created",
      header: "Created at",
      size: 80,
    },
    {
      accessorKey: "invoice_status",
      header: "Status",
      size: 80,
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue()}
          variant="filled"
          color={cell.getValue() === "Paid" ? "success" : "error"}
          sx={{ minWidth: 50 }}
        />
      ),
    },
    {
      accessorKey: "invoice_total",
      header: "Total",
      size: 80,
    },
    {
      accessorKey: "invoice_paid",
      header: "Paid",
      size: 80,
    },
    {
      accessorKey: "payment_amount",
      header: "Amount",
      size: 80,
    },
    {
      accessorKey: "invoice_balance",
      header: "Balance",
      size: 80,
    },
    {
      accessorKey: "payment_method_name",
      header: "Payment Method",
      size: 80,
    },
    {
      accessorKey: "payment_date",
      header: "Payment Date",
    },
    {
      id: "invoice_url",
      accessorKey: "invoice_url",
      size: 80,
      header: "url",
      Cell: ({ cell }) => (
        <Link href={cell.getValue()} target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    const payload = {
      startDate: format(
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(Date.now()), "yyyy-MM-dd"),
    };
    dispatch(downloadReport(payload));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tableRows: any[] = reports.map((report: IInvoiceReport) => ({
      invoice_number: report.invoice_number,
      invoice_date_created: report.invoice_date_created,
      invoice_status: report.invoice_status,
      invoice_total: report.invoice_total,
      invoice_paid: report.invoice_paid,
      payment_amount: report.payment_amount,
      invoice_balance: report.invoice_balance,
      payment_date: report.payment_date,
      invoice_url: report.invoice_url,
      payment_method_name: report.payment_method_name,
    }));
    setRows(tableRows);
  }, [reports]);

  const formikRef = useRef(null);

  return (
    <Box sx={{ width: 1 }}>
      <MaterialReactTable
        columns={columns}
        data={rows}
        initialState={{
          density: "compact",
          pagination: { pageIndex: 0, pageSize: 10 },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Formik
                initialValues={{
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(Date.now()),
                }}
                innerRef={formikRef}
                onSubmit={() => {}}
              >
                {({ values, touched, errors, getFieldProps }) => (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikControl
                        control="CalendarTime"
                        label="Start Date"
                        dateOrTimeOnly="date"
                        name="startDate"
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                        {...getFieldProps("startDate")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikControl
                        control="CalendarTime"
                        label="End Date"
                        dateOrTimeOnly="date"
                        name="endDate"
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                        {...getFieldProps("endDate")}
                      />
                    </Grid>
                    <Grid item>
                      <ContainedButton
                        onClick={() => {
                          const payload = {
                            startDate: format(values.startDate, "yyyy-MM-dd"),
                            endDate: format(values.endDate, "yyyy-MM-dd"),
                          };
                          dispatch(downloadReport(payload));
                        }}
                      >
                        Search
                      </ContainedButton>
                    </Grid>
                  </Grid>
                )}
              </Formik>
              <CSVLink
                data={[
                  columns.map((data) => data.header),
                  ...reports.map((report: IInvoiceReport) => [
                    report.invoice_number,
                    report.invoice_date_created,
                    report.invoice_status,
                    report.invoice_total,
                    report.invoice_paid,
                    report.payment_amount,
                    report.invoice_balance,
                    report.payment_method_name,
                    report.payment_date,
                    report.invoice_url,
                  ]),
                ]}
                onClick={() => {
                  if (table.getPrePaginationRowModel().rows.length === 0)
                    return false;
                }}
              >
                <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  //export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() => {}}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  Export All
                </Button>
              </CSVLink>
            </Box>
          </>
        )}
      />
    </Box>
  );
};

import { CTabView } from "src/components/CTabView";
import { OperationalDashboardPanel } from "./operational-dashboard-panel";
import { FinancialDashboardPanel } from "./financial-dashboard-panel";

export function Dashboard() {
  return (
    <>
      <CTabView
        data={[
          {
            label: "OPERATIONAL DASHBOARD",
            component: <OperationalDashboardPanel />,
          },
          {
            label: "FINANCIAL DASHBOARD",
            component: <FinancialDashboardPanel />,
          },
        ]}
      />
    </>
  );
}

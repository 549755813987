import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import CModal from "src/components/CModal";
import { setDialog } from "src/slices/uiSettingsSlice";
import { AppDispatch, RootState } from "src/store";
import { ComplaintForm } from "./complaint-form";

export const CreateComplaintFormModal = () => {
  const { dialog } = useSelector((state: RootState) => state.uiSettings);
  const dispatch = useDispatch<AppDispatch>();

  return dialog.CreateComplaintForm !== undefined &&
    dialog.CreateComplaintForm ? (
    <CModal
      dialogName="CreateComplaintForm"
      title="Create Complaint Form"
      maxWidth="md"
      confirmOnCancel
      handleSaveButtonText="Save"
      handleCancelButtonText="Exit"
      handleCancel={() =>
        dispatch(setDialog({ open: false, dialogName: "CreateComplaintForm" }))
      }
      open={
        dialog.CreateComplaintForm === undefined
          ? false
          : dialog.CreateComplaintForm
      }
      content={<ComplaintForm dialogName="CreateComplaintForm" />}
    />
  ) : null;
};

export const UpdateComplaintFormModal: FC<{
  customerId: string;
  complaintId: string;
}> = ({ customerId, complaintId }) => {
  const { dialog } = useSelector((state: RootState) => state.uiSettings);
  const dispatch = useDispatch<AppDispatch>();

  return dialog.UpdateComplaintForm !== undefined &&
    dialog.UpdateComplaintForm ? (
    <CModal
      dialogName="UpdateComplaintForm"
      title="Update Complaint Form"
      maxWidth="md"
      confirmOnCancel
      handleSaveButtonText="Save"
      handleCancelButtonText="Exit"
      handleCancel={() =>
        dispatch(setDialog({ open: false, dialogName: "UpdateComplaintForm" }))
      }
      open={
        dialog.UpdateComplaintForm === undefined
          ? false
          : dialog.UpdateComplaintForm
      }
      content={
        <ComplaintForm
          dialogName="UpdateComplaintForm"
          customerId={customerId}
          complaintId={complaintId}
        />
      }
    />
  ) : null;
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../types/auth/auth";
import { Slices } from "../constants";

const initialState: AuthState = {
  isLoggedIn: false,
  user: null,
};

const slice = createSlice({
  name: Slices.LOGIN_INFORMATION,
  initialState: { ...initialState },

  reducers: {
    setLoginUser(state: AuthState, action: PayloadAction<AuthState>): void {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.user = action.payload.user;
    },
    setLogout(state: AuthState): void {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const { setLoginUser, setLogout } = slice.actions;
export const { reducer } = slice;

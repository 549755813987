import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface InfoCardProps {
  title: string;
  title1: string;
  title2?: string;
  barTitle1: string;
  barTitle2: string;
  value1: any;
  value2?: any;
  icon?: any;
  iconBackgoroundColor?: string;
  barChartList1?: Array<InfoCardBarChartProps>;
  barChartList2?: Array<InfoCardBarChartProps>;
}

interface InfoCardBarChartProps {
  month: any;
  year: any;
  value?: any;
}

export const MultiBarInfoCard: FC<InfoCardProps> = ({
  title,
  title1,
  title2,
  barTitle1,
  barTitle2,
  value1,
  value2,
  barChartList1,
  barChartList2,
}) => {
  const [alignment, setAlignment] = useState("monthly");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const labels = useMemo(
    () => [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const [data, setData] = useState(() => ({
    labels,
    datasets: [
      {
        label: barTitle1,
        data: barChartList1.map((value) => value.value),
        backgroundColor: "#10b9b2",
        // barPercentage: 0.3, // Adjust this value to decrease the bar width
        // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
      },
      {
        label: barTitle2,
        data: barChartList2.map((value) => value.value),
        backgroundColor: "#595757",
        // barPercentage: 0.3, // Adjust this value to decrease the bar width
        // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
      },
    ],
  }));

  useEffect(() => {
    if (alignment === "monthly") {
      setData({
        labels,
        datasets: [
          {
            label: barTitle1,
            data: labels.map((_, index) => {
              let selectedValue = barChartList1
                .filter(
                  (value) =>
                    value.year ===
                    Math.max(...barChartList1.map((value) => value.year))
                )
                .filter((data) => data.month === index + 1);
              if (selectedValue.length !== 0) {
                return selectedValue[0].value;
              }
              return 0;
            }),
            backgroundColor: "#10b9b2",
            // barPercentage: 0.3, // Adjust this value to decrease the bar width
            // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
          },
          {
            label: barTitle2,
            data: labels.map((_, index) => {
              let selectedValue = barChartList2
                .filter(
                  (value) =>
                    value.year ===
                    Math.max(...barChartList2.map((value) => value.year))
                )
                .filter((data) => data.month === index + 1);
              if (selectedValue.length !== 0) {
                return selectedValue[0].value;
              }
              return 0;
            }),
            backgroundColor: "#595757",
            // barPercentage: 0.3, // Adjust this value to decrease the bar width
            // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
          },
        ],
      });
    } else {

      // Bar 1 data
      let years1 = Array.from(new Set(barChartList1.map((d) => d.year)));
      years1 = years1.sort();
      const yearCounts1 = [];
      for (const year of years1) {
        const filteredByYear = barChartList1.filter((d) => d.year === year);
        const sum = filteredByYear.reduce((total, val) => total + val.value, 0);
        yearCounts1.push(sum);
      }

      // Bar 2 data
      let years2 = Array.from(new Set(barChartList2.map((d) => d.year)));
      years2 = years2.sort();
      const yearCounts2 = [];
      for (const year of years2) {
        const filteredByYear = barChartList2.filter((d) => d.year === year);
        const sum = filteredByYear.reduce((total, val) => total + val.value, 0);
        yearCounts2.push(sum);
      }

      setData({
        labels: years1,
        datasets: [
          {
            label: barTitle1,
            data: yearCounts1,
            backgroundColor: "#10b9b2",
            // barPercentage: 0.3, // Adjust this value to decrease the bar width
            // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
          },
          {
            label: barTitle2,
            data: yearCounts2,
            backgroundColor: "#595757",
            // barPercentage: 0.3, // Adjust this value to decrease the bar width
            // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
          },
        ],
      });
    }
  }, [title, barTitle1, barTitle2, labels, barChartList1, barChartList2 , alignment]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: false,
        text: title,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        // ticks: {
        //   // forces step size to be 50 units
        //   stepSize: 50
        // }
      },
    },
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ alignItems: "center" }}>
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  {title1}
                </Typography>
              </Grid>
              <Grid item xs={true}>
                <Typography color="textPrimary" variant="h4">
                  {value1}
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  {title2}
                </Typography>
              </Grid>
              <Grid item xs={true}>
                <Typography color="textPrimary" variant="h4">
                  {value2}
                </Typography>
              </Grid>

              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="monthly">Monthly</ToggleButton>
                  <ToggleButton value="yearly">Yearly</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Bar options={options} data={data} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import {
  downloadReport,
  fetchPaymentMethodCount,
  fetchRecentInvoices,
  fetchRevenueByStatus,
  fetchTotalInvoices,
  fetchTotalSales,
} from "./thunks";
import { IFinancialDashboardSlice } from "./types";

const initialState: IFinancialDashboardSlice = {
  totalSales: [],
  totalInvoices: [],
  totalRevenue: [],
  totalPaymentMethodCounts: [],
  totalRecentInvoices: [],
  downloadedReports: [],
  isError: false,
  isLoading: false,
};

export const financialDashboardSlice = createSlice({
  name: "financialDashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchTotalSales
    builder.addCase(fetchTotalSales.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalSales.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalSales = action.payload;
    });
    builder.addCase(fetchTotalSales.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // totalInvoices
    builder.addCase(fetchTotalInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTotalInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalInvoices = action.payload;
    });
    builder.addCase(fetchTotalInvoices.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchRevenueByStatus
    builder.addCase(fetchRevenueByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRevenueByStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalRevenue = action.payload;
    });
    builder.addCase(fetchRevenueByStatus.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchPaymentMethodCount
    builder.addCase(fetchPaymentMethodCount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPaymentMethodCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalPaymentMethodCounts = action.payload;
    });
    builder.addCase(fetchPaymentMethodCount.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchRecentInvoices
    builder.addCase(fetchRecentInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRecentInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalRecentInvoices = action.payload;
    });
    builder.addCase(fetchRecentInvoices.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // downloadReport
    builder.addCase(downloadReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadReport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.downloadedReports = action.payload;
    });
    builder.addCase(downloadReport.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { reducer } = financialDashboardSlice;

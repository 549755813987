import { Grid } from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { toastMessage } from "src/slices/commonSlice";
import {
  selectExpenseTypes,
  selectUnitPriceItems,
} from "src/slices/configuration/selectors";
import {
  addUnitPrice,
  fetchExpenseTypes,
  fetchUnitPriceItems,
} from "src/slices/configuration/thunks";
import {
  IAddUnitPricePayload,
  IExpenseTypes,
  IUnitPriceItem,
} from "src/slices/configuration/types";
import { AppDispatch } from "src/store";

interface IformInitialValue {
  expenseType: string;
  price: string;
  startDate: Date;
  endDate: Date;
}

export default function ExpensePanel() {
  const dispatch = useDispatch<AppDispatch>();

  const formikRef = useRef(null);

  const expenseTypes: IExpenseTypes[] = useSelector(selectExpenseTypes);
  const unitPriceItems: IUnitPriceItem[] = useSelector(selectUnitPriceItems);

  // Form Inital Values
  const formInitialValue: IformInitialValue = {
    expenseType: "1",
    price: "",
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  };

  // Click Events
  const onClickAddPricing = async (values: IformInitialValue) => {
    const isNum = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(values.price);
    if (isNum) {
      const unitPricePayload: IAddUnitPricePayload = {
        expenseTypeId: parseInt(values.expenseType),
        unitPrice: parseFloat(values.price),
        startDate: format(values.startDate, "yyyy-MM-dd"),
        endDate: format(values.endDate, "yyyy-MM-dd"),
      };
      const response = await dispatch(addUnitPrice(unitPricePayload));
      if (response.payload) {
        dispatch(fetchUnitPriceItems());
        formikRef.current.setFieldValue("price", "");
        formikRef.current.setFieldValue("startDate", new Date(Date.now()));
        formikRef.current.setFieldValue("endDate", new Date(Date.now()));
      }
    } else {
      dispatch(
        toastMessage({
          error: true,
          message: "Price must be a valid number",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchExpenseTypes());
    dispatch(fetchUnitPriceItems());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Formik
        initialValues={formInitialValue}
        innerRef={formikRef}
        onSubmit={(values) => {
          onClickAddPricing(values);
        }}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form id="Configuration">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3} pb={3} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="SelectField"
                      name="expenseType"
                      label={"Expense Type"}
                      required
                      options={expenseTypes?.map((expenseType) => ({
                        value: expenseType.id,
                        label: expenseType.type,
                      }))}
                      error={Boolean(touched.expenseType && errors.expenseType)}
                      helperText={touched.expenseType && errors.expenseType}
                      {...getFieldProps("expenseType")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="InputField"
                      name="price"
                      label={"Price"}
                      required
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      {...getFieldProps("price")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="CalendarTime"
                      label="Start Date"
                      dateOrTimeOnly="date"
                      name="startDate"
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      {...getFieldProps("startDate")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="CalendarTime"
                      label="End Date"
                      dateOrTimeOnly="date"
                      name="endDate"
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      {...getFieldProps("endDate")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ContainedButton size="medium" type="submit">
                      Add Pricing
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MaterialReactTable
                      columns={[
                        {
                          accessorKey: "id",
                          header: "ID",
                          size: 100
                        },
                        {
                          accessorKey: "type",
                          header: "Type",
                          size: 100
                        },
                        {
                          accessorKey: "unitPrice",
                          header: "Unit Price",
                          size: 100
                        },
                        {
                          accessorKey: "startDate",
                          header: "Start Date",
                          size: 100
                        },
                        {
                          accessorKey: "endDate",
                          header: "End Date",
                          size: 100
                        },
                        {
                          accessorKey: "createdAt",
                          header: "Created At",
                          size: 100
                        },
                      ]}
                      data={unitPriceItems?.map((unitPriceItem) => ({
                        id: unitPriceItem.id,
                        type: "Fuel",
                        unitPrice: unitPriceItem.unitPrice,
                        startDate: new Date(
                          unitPriceItem.startDate
                        ).toLocaleDateString(),
                        endDate: new Date(
                          unitPriceItem.endDate
                        ).toLocaleDateString(),
                        createdAt: new Date(
                          unitPriceItem.createdAt
                        ).toLocaleDateString(),
                      }))}
                      initialState={{
                        density: "compact",
                        pagination: { pageSize: 5, pageIndex: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

// URLs
const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

const AuthAPIService = {
  LOGIN: "/user/login-user",
};

const DashboardAPIService = {
  GET_DASHBOARD_DATA: "dashboard/get-dashboard-data",
  GET_TOTAL_ORDERS: "dashboard/get-total-orders",
  GET_TOTAL_COMPLAINTS: "dashboard/get-total-complaints",
  GET_TOTAL_ORDERS_BY_STATUS: "dashboard/get-total-orders-by-status",
  GET_TOTAL_ORDERS_BY_SERVICES: "dashboard/get-total-services",
};

const FinancialDashboardAPIService = {
  GET_TOTAL_REVENUE: "financial-dashboard/get-total-revenue",
  GET_REVENUE_BY_PAYMENT: "financial-dashboard/get-revenue-by-payment",
  GET_PAYMENT_METHOD_COUNT: "financial-dashboard/get-payment-method-count",
  GET_REVENUE_BY_STATUS: "financial-dashboard/get-revenue-by-status",
  GET_RECENT_INVOICES: "financial-dashboard/get-recent-invoices",
  DOWNLOAD_REPORT: "financial-dashboard/download-report",
};

const CustomerAPIService = {
  CREATE_UPDATE_CUSTOMER: "/customer/create-update-customer",
  GET_CUSTOMER_IDS_LIST: "/customer/get-customer-ids",
  GET_CUSTOMERS_LIST: "/customer/get-customers-list",
  GET_CUSTOMER_BY_ID: "/customer/get-customer-by-id",
  GET_CUSTOMER_ADDRESSES: "/customer-address/get-customer-address",
  GET_CUSTOMER_COUNTS: "customer/get-customer-counts",
};

const OrderAPIService = {
  CREATE_UPDATE_ORDER: "/order/create-update-order",
  GET_ORDERS_LIST: "/order/get-orders",
  GET_ORDERS_LIST_PAGINATION: "order/get-orders-pagination",
  GET_ORDER_DETAILS: "/order/get-order-details",
  GET_ORDER_BY_CUSTOMER_ID: "/order/get-order-by-customer-id",
  GET_ORDER_COUNT_BY_CUSTOMER_ID: "/order/get-order-count",
  GET_COMPLAINTS: "/order/get-complaints",
  GET_COMPLAINT_BY_ID: "/order/get-complaint-by-id",
  GET_COMPLAINT_BY_CUSTOMER_ID: "/order/get-complaint-by-customer-id",
  CREATE_UPDATE_COMPLAINT: "/order/create-update-complaint",
  GET_COMPLAINTS_CATEGORY: "order/get-complaints-category",
  GET_ORDER_IDS: "/order/get-order-ids",
  UPLOAD_ATTACHMENT: "/order/upload-attachments",
  GET_FILTERED_ORDERS: "/order/get-filtered-orders",
};

const BusinessProcessAPIService = {
  GET_SERVICES_LIST: "/services/get-services",
  GET_ALL_SERVICES_LIST: "/services/get-all-services",
  CREATE_SERVICE: "/services/create-service",
  DISABLE_SERVICE: "/services/disable-service",
  ENABLE_SERVICE: "/services/enable-service",

  SET_PRICE: "/services/set-price",
  GET_PRICE: "/services/get-prices",

  GET_ITEMS_LIST: "/item-types/get-item-types",
  GET_ALL_ITEMS_LIST: "/item-types/get-all-items",
  CREATE_ITEM: "/item-types/create-item-type",
  DISABLE_ITEM: "/item-types/disable-item",
  ENABLE_ITEM: "/item-types/enable-item",

  GET_EXPENSE_TYPES: "/expense-management/get-expense-types",
  GET_UNIT_PRICE_ITEMS: "/expense-management/get-unit-prices",
  ADD_UNIT_PRICE: "/expense-management/add-unit-price",
};

// REGEXs
const REGEX_IS_NUMBER = /^\d+$/;

// Routes
const RoutePaths = {
  LOGIN: "/login",

  SEARCH_CUSTOMER: "/search-customer",
  CREATE_CUSTOMER: "/create-customer",
  UPDATE_CUSTOMER: "/update-customer",
  CUSTOMER_DETAILS: "/customer-details",
  CUSTOMER_INFO: "/customer-info",

  COMPLAINT_LISTING: "/complaint-listing",

  CREATE_ORDER: "/create-order",
  UPDATE_ORDER: "/update-order",
  ORDER_LISTING: "/order-listing",
  ORDER_DETAILS: "/order-details",
  CUSTOMER_ORDER_INFO: "/customer-order-info",

  CONFIGURATION: "/configuration",
  FINANCIAL_REPORT: "/financial-report",
};

// Slices
const Slices = {
  LOGIN_INFORMATION: "loginInformation",
  COMMON_REDUCER: "commonReducer",
  UI_SETTINGS: "uiSettings",
};

const GENDER = {
  M: "Male",
  F: "Female",
};

const ORDER_STATUS = {
  "NEW ORDER": "1",
  "READY FOR PICKUP": "2",
  "IN PROGRESS": "3",
  "READY FOR DELIVERY": "4",
  DELIVERED: "5",
};

function getInvoiceStatusValue(invoiceStatus: number) {
  switch (invoiceStatus) {
    case 1:
      return "Draft";
    case 2:
      return "Sent";
    case 3:
      return "View";
    case 4:
      return "Paid";
  }
}

export {
  BASE_URL,
  DashboardAPIService,
  FinancialDashboardAPIService,
  AuthAPIService,
  CustomerAPIService,
  OrderAPIService,
  BusinessProcessAPIService,
  REGEX_IS_NUMBER,
  RoutePaths,
  Slices,
  GENDER,
  ORDER_STATUS,
  getInvoiceStatusValue,
};

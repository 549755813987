import axios from "axios";
import { BASE_URL } from "src/constants";
import { getItem, setItem } from "./utils";

interface ReturnInterface {
  error: boolean;
  result: any;
}

axios.interceptors.request.use(
  config => {
    const auth = getItem('auth')
    if (auth) {
      const token = JSON.parse(auth)?.token ?? ''
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (error.response.status === 403) {
      const auth = getItem('auth')
      const refreshToken = JSON.parse(auth)?.refreshToken ?? ''
      const userId = JSON.parse(auth)?.currentUserId ?? ''
      return axios
        .post(`${BASE_URL}/user/refresh-token`, {
          refreshToken: refreshToken,
          userId,
          grantType:"refresh_token"
        })
        .then(res => {
          if (res.status === 200) {
            setItem('auth', JSON.stringify({
              token: res.data.data.accessToken,
              refreshToken: res.data.data.refreshToken,
              currentUserId: userId
             }))
            return axios(originalRequest)
          }
        }).catch(err => {
          localStorage.clear()
          window.location.href="/login"
        })
    }
    return Promise.reject(error)
  }
)


export const sendRequestJSON = async (
  obj = null,
  service,
  method: string
): Promise<ReturnInterface> => {
  let config: any;
  if (obj) {
    const data = JSON.stringify(obj);
    // const token = localStorage.getItem('AccessToken');
    config = {
      method,
      url: `${BASE_URL}${service}`,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 2 * 60 * 1000,
      data,
    };
  } else {
    config = {
      method,
      url: `${BASE_URL}/${service}`,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 2 * 60 * 1000,
    };
  }

  const returnOBJ: any = { error: false, result: {} };

  await axios(config)
    .then(async (response) => {
      const result: any = response;
      returnOBJ.result = result;
    })
    .catch(async (err) => {
      console.log("result axios",err, err?.response);
      returnOBJ.error = true;
      returnOBJ.result = err.response?.data;
    });

  try {
    if (returnOBJ.error === true) {
      return returnOBJ;
    } else if (returnOBJ.error === false) {
      return returnOBJ;
    }
  } catch (e) {
    return null;
  }
};

export const sendRequestFiles = async (
  files,
  service
): Promise<ReturnInterface> => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("attachmentFile", files[i]);
  }
  const returnOBJ: any = { error: false, result: {} };
  try {
    const response = await axios.post(`${BASE_URL}${service}`, formData, {
      timeout: 1800000,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(response);
    returnOBJ.result = response;
    returnOBJ.error = false;
    return returnOBJ;
  } catch (error) {
    console.error(error);
    returnOBJ.result = error;
    returnOBJ.error = true;
    return returnOBJ;
  }
};

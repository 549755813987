import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useNavigate, useParams } from "react-router-dom";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { GENDER, RoutePaths } from "src/constants";
import { ContainedButton } from "src/components/button-group";
import { GoogleLocationCell } from "./GoogleLocationCell";
import { AppDispatch } from "src/store";
import { ICustomer } from "src/slices/customers/types";
import { fetchCustomerDetailsById } from "src/slices/customers/thunks";
import { selectCustomerDetails } from "src/slices/customers/selectors";

export function CustomerInfo() {
  const { customerId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const customerDetails = useSelector(selectCustomerDetails);

  const formikRef = useRef(null);

  const [addressColumns] = useState<any[]>([
    {
      accessorKey: "address",
      header: "Address",
      size: 100,
    },
    {
      accessorKey: "googleLocation",
      header: "Google Location",
      size: 100,
      Cell: GoogleLocationCell,
    },
  ]);
  const [addressRows, setAddressRows] = useState([]);

  // Form Inital Values
  const formInitialValue = {
    customerFullName: "",
    gender: "",
    dateOfBirth: new Date(Date.now()),
    contactNumber: "",
    alternateNumber: "",
    emailAddress: "",
    address: "",
    addressLocation: "",
    isRecurring: false,
  };

  useEffect(() => {
    if (addressRows.length > 0) {
      formikRef.current.setFieldValue("pickupAddress", addressRows[0].id);
      formikRef.current.setFieldValue(
        "pickupLocation",
        addressRows[0].googleLocation ?? ""
      );
      formikRef.current.setFieldValue("deliveryAddress", addressRows[0].id);
      formikRef.current.setFieldValue(
        "deliveryLocation",
        addressRows[0].googleLocation ?? ""
      );
    }
  }, [addressRows]);

  // UI Helper
  const loadCustomerData = async (customer: ICustomer) => {
    const { setFieldValue } = formikRef.current;
    setFieldValue("customerFullName", customer.name);
    setFieldValue("gender", customer.gender ? GENDER[customer.gender] : "");
    setFieldValue("dateOfBirth", new Date(customer.dob));
    setFieldValue("contactNumber", customer.primaryNumber);
    setFieldValue("alternateNumber", customer.secondaryNumber ?? "");
    setFieldValue("emailAddress", customer.email ?? "");
    setFieldValue("isRecurring", customer.isRecurring);
    setAddressRows(
      customer.addresses.map((address, index) => ({ ...address, id: index }))
    );
  };

  useEffect(() => {
    dispatch(fetchCustomerDetailsById(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerDetails) {
      loadCustomerData(customerDetails);
    }
  }, [customerDetails]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          initialValues={formInitialValue}
          innerRef={formikRef}
          onSubmit={() => {}}
        >
          {({ values, touched, errors, getFieldProps }) => (
            <Form id="CreateOrder">
              {/* Personal Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Personal Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="customerFullName"
                    label={"Customer Full Name"}
                    disabled
                    error={Boolean(
                      touched.customerFullName && errors.customerFullName
                    )}
                    helperText={
                      touched.customerFullName && errors.customerFullName
                    }
                    {...getFieldProps("customerFullName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="SelectField"
                    name="gender"
                    label="Gender"
                    options={[
                      {
                        label: "Male",
                        value: "Male",
                      },
                      {
                        label: "Female",
                        value: "Female",
                      },
                    ]}
                    disabled
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    {...getFieldProps("gender")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="CalendarTime"
                    label="Date of Birth"
                    dateOrTimeOnly="date"
                    name="dateOfBirth"
                    disabled
                    disableFuture
                    error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    {...getFieldProps("dateOfBirth")}
                  />
                </Grid>
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={1} pb={3}>
                <Grid item xs={12}>
                  <CTypography fontWeight="bold">
                    Contact Information
                  </CTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    required
                    name="contactNumber"
                    label="Contact Number"
                    disabled
                    error={Boolean(
                      touched.contactNumber && errors.contactNumber
                    )}
                    helperText={touched.contactNumber && errors.contactNumber}
                    {...getFieldProps("contactNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="alternateNumber"
                    label="Alternate Number"
                    disabled
                    error={Boolean(
                      touched.alternateNumber && errors.alternateNumber
                    )}
                    helperText={
                      touched.alternateNumber && errors.alternateNumber
                    }
                    {...getFieldProps("alternateNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="InputField"
                    name="emailAddress"
                    label="Email Address"
                    disabled
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    helperText={touched.emailAddress && errors.emailAddress}
                    {...getFieldProps("emailAddress")}
                  />
                </Grid>
                <Grid item xs={12} pb={3}>
                  <Box sx={{ width: 1 }}>
                    <MaterialReactTable
                      columns={addressColumns}
                      data={addressRows.map((address) => ({
                        address: address.address,
                        googleLocation: address.googleLocation,
                      }))}
                      enableColumnActions={false}
                      enableColumnFilters={false}
                      enablePagination={false}
                      enableSorting={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      muiTableBodyRowProps={{ hover: false }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isRecurring}
                        name="isRecurring"
                        disabled
                        {...getFieldProps("isRecurring")}
                      />
                    }
                    label="Is Customer Recurring?"
                  />
                </Grid>
                <Grid item>
                  <ContainedButton
                    onClick={() => {
                      navigate(`${RoutePaths.UPDATE_CUSTOMER}/${customerId}`);
                    }}
                  >
                    UPDATE CUSTOMER
                  </ContainedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

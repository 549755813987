import { CustomerInfo } from "./customer-info-panel";
import { CustomerOrders } from "./customer-orders-panel";
import { ComplaintPanel } from "./complaint-panel";
import { CTabView } from "src/components/CTabView";

export function CustomerDetails() {
  return (
    <CTabView
      data={[
        { label: "Customer Info", component: <CustomerInfo /> },
        { label: "Customer Orders", component: <CustomerOrders /> },
        { label: "Complaint", component: <ComplaintPanel /> },
      ]}
    />
  );
}

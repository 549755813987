import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Chip, Grid, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { OutlinedButton } from "src/components/button-group";
import { AppDispatch } from "src/store";
import { fetchComplaints } from "src/slices/complaints/thunks";
import { selectComplaints } from "src/slices/complaints/selectors";
import { IComplaint } from "src/slices/complaints/types";
import { ImageVideoViewer } from "src/components/image-video-viewer";
import { setDialog } from "src/slices/uiSettingsSlice";
import {
  CreateComplaintFormModal,
  UpdateComplaintFormModal,
} from "./complaint-form-modal";

export function ComplaintsList() {
  const dispatch = useDispatch<AppDispatch>();

  const complaints: IComplaint[] = useSelector(selectComplaints);
  const [selectedComplaintId, setSelectedComplaintId] = useState<string>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>(null);

  const [complaintsState, setComplaintsState] = useState<IComplaint[]>([]);

  const [viewAttachments, setViewAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    dispatch(fetchComplaints());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setComplaintsState(complaints);
  }, [complaints]);

  useEffect(() => {
    if (attachments.length > 0) {
      setViewAttachments(true);
    }
  }, [attachments]);

  const [columns] = useState<any>([
    { accessorKey: "complaint", header: "Complaint", size: 60 },
    { accessorKey: "order", header: "Order", size: 60 },
    {
      accessorKey: "category",
      header: "Category",
      size: 70,
    },
    {
      accessorKey: "detail",
      header: "Detail",
      Cell: ({ cell }) => (
        <Typography sx={{ overflow: "auto" }}>{cell?.getValue()}</Typography>
      ),
    },
    {
      accessorKey: "resolved",
      header: "Resolved",
      size: 70,
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue() === "true" ? "YES" : "NO"}
          variant="filled"
          color={cell.getValue() === "true" ? "success" : "error"}
          sx={{ minWidth: 50 }}
        />
      ),
    },
    {
      accessorKey: "attachment",
      header: "Attachment",
      size: 100,
      Cell: ({ cell }) => (
        <Grid container spacing={1}>
          {cell?.getValue() && cell.getValue().length > 0 ? (
            <Grid item xs={12}>
              <OutlinedButton
                onClick={() => {
                  const rowAttachments = cell.getValue().map((data) => {
                    if (data.fileType === "video") {
                      return {
                        src: data.fileUrl,
                        title: data.fileName,
                        type: "video",
                        sources: [
                          {
                            src: data.fileUrl,
                            type: "video/mp4",
                          },
                        ],
                      };
                    }
                    return {
                      src: data.fileUrl,
                      title: data.fileName,
                    };
                  });
                  setAttachments(rowAttachments);
                }}
              >
                View Attachments
              </OutlinedButton>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      ),
    },
  ]);

  return (
    <>
      <Box sx={{ width: 1 }}>
        <MaterialReactTable
          columns={columns}
          data={complaintsState.map((complaint) => ({
            complaint: complaint.complaintId,
            customerId: complaint.customerId,
            order: `LX-${complaint.orderId}`,
            category: complaint.category,
            detail: complaint.description,
            resolved: complaint.isResolved.toString(),
            attachment:
              complaint.attachments.length > 0
                ? complaint.attachments.map((link) => link)
                : [],
          }))}
          muiTableProps={{
            sx: {
              tableLayout: "fixed",
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 10 },
            columnVisibility: { complaint: false },
          }}
          renderTopToolbarCustomActions={() => (
            <OutlinedButton
              onClick={() => {
                dispatch(
                  setDialog({ open: true, dialogName: "CreateComplaintForm" })
                );
              }}
            >
              Create Complaint
            </OutlinedButton>
          )}
          enableRowActions
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key={0}
              onClick={() => {
                closeMenu();
                setSelectedComplaintId(row.original.complaint);
                setSelectedCustomerId(row.original.customerId);
                dispatch(
                  setDialog({ open: true, dialogName: "UpdateComplaintForm" })
                );
              }}
              sx={{ m: 0 }}
            >
              Update Complaint
            </MenuItem>,
          ]}
        />
      </Box>
      <CreateComplaintFormModal />
      <UpdateComplaintFormModal
        customerId={selectedCustomerId}
        complaintId={selectedComplaintId}
      />
      <ImageVideoViewer
        isOpen={viewAttachments}
        onClose={() => {
          setViewAttachments(false);
          setAttachments([]);
        }}
        attachments={attachments}
      />
    </>
  );
}

import Lightbox, { CarouselSettings } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Video from "yet-another-react-lightbox/plugins/video";
import { IImageVideoViewer } from "./types";
import { FC } from "react";

export const ImageVideoViewer: FC<IImageVideoViewer> = ({
  isOpen,
  onClose,
  attachments,
}) => {
  const carouselSettings: CarouselSettings = {
    finite: true,
    preload: 0,
    padding: "",
    spacing: "",
    imageFit: "contain",
  };

  return (
    <>
      <Lightbox
        open={isOpen}
        close={onClose}
        slides={attachments}
        carousel={carouselSettings}
        plugins={[Captions, Zoom, Video]}
      />
    </>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerAPIService } from "src/constants";
import { sendRequestJSON } from "src/lib/axios";
import { ICreateUpdateCustomerPayload } from "src/pages/customer/types";
import { toastMessage } from "../commonSlice";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomer",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        CustomerAPIService.GET_CUSTOMERS_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchCustomerIds = createAsyncThunk(
  "customers/fetchCustomerIds",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        CustomerAPIService.GET_CUSTOMER_IDS_LIST,
        "get"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchCustomerDetailsById = createAsyncThunk(
  "customers/fetchCustomerDetailsById",
  async (customerId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          customerId,
        },
        CustomerAPIService.GET_CUSTOMER_BY_ID,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchCustomerAddresses = createAsyncThunk(
  "customers/fetchCustomerAddresses",
  async (customerId: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          customerId,
        },
        CustomerAPIService.GET_CUSTOMER_ADDRESSES,
        "post"
      );
      if (!response.error) {
        const { data } = response?.result?.data;
        return data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
        return [];
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const createUpdateCustomer = createAsyncThunk(
  "customers/createUpdateCustomer",
  async (customer: ICreateUpdateCustomerPayload, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        customer,
        CustomerAPIService.CREATE_UPDATE_CUSTOMER,
        "post"
      );
      if (response.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data.message,
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./pages/ErrorPage";
import { Login } from "./pages/auth";
import { Configuration } from "./pages/configuration";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import { RoutePaths } from "./constants";
import CToastInfoDialog from "./components/CToastInfoDialog";
import { OrderDetails, CreateAndUpdateOrder, OrdersList } from "./pages/order";
import {
  SearchCustomer,
  CreateAndUpdateCustomer,
  CustomerDetails,
} from "./pages/customer";
import { Dashboard } from "./pages/dashboard";
import { ComplaintsList } from "./pages/complaint";
import { ReportPage } from "./pages/report";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.SEARCH_CUSTOMER,
        element: (
          <ProtectedRoute>
            <SearchCustomer />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.CREATE_CUSTOMER,
        element: (
          <ProtectedRoute>
            <CreateAndUpdateCustomer />
          </ProtectedRoute>
        ),
      },
      {
        path: `${RoutePaths.UPDATE_CUSTOMER}/:customerId`,
        element: (
          <ProtectedRoute>
            <CreateAndUpdateCustomer />
          </ProtectedRoute>
        ),
      },
      {
        path: `${RoutePaths.CUSTOMER_DETAILS}/:customerId`,
        element: (
          <ProtectedRoute>
            <CustomerDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.COMPLAINT_LISTING,
        element: (
          <ProtectedRoute>
            <ComplaintsList />
          </ProtectedRoute>
        ),
      },
      {
        path: `${RoutePaths.ORDER_DETAILS}/:orderId`,
        element: (
          <ProtectedRoute>
            <OrderDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.CREATE_ORDER,
        element: (
          <ProtectedRoute>
            <CreateAndUpdateOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.UPDATE_ORDER,
        element: (
          <ProtectedRoute>
            <CreateAndUpdateOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.ORDER_LISTING,
        element: (
          <ProtectedRoute>
            <OrdersList />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.FINANCIAL_REPORT,
        element: (
          <ProtectedRoute>
            <ReportPage />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutePaths.CONFIGURATION,
        element: (
          <ProtectedRoute>
            <Configuration />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: RoutePaths.LOGIN,
    element: (
      <>
        <CToastInfoDialog />
        <Login />
      </>
    ),
  },
  {
    path: "/*",
    element: <ErrorPage />,
  },
]);

import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialReactTable from "material-react-table";
import { Chip, Grid } from "@mui/material";
import { CustomersTableActionBar } from "./customers-table-action-bar";
import { CustomersTableActionMenu } from "./customers-table-action-menu";
import { AppDispatch } from "src/store";
import { selectCustomers } from "src/slices/customers/selectors";
import { fetchCustomers } from "src/slices/customers/thunks";
import { ICustomer } from "src/slices/customers/types";

export function SearchCustomer() {
  const dispatch = useDispatch<AppDispatch>();
  const customers = useSelector(selectCustomers);

  const [columns] = useState<any>([
    {
      accessorKey: "customerName",
      header: "Customer Name",
    },
    {
      accessorKey: "primaryNumber",
      header: "Primary Number",
    },
    {
      accessorKey: "address",
      header: "Address",
    },
    {
      accessorKey: "isRecurring",
      header: "Is Recurring",
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue() === "true" ? "YES" : "NO"}
          variant="filled"
          color={cell.getValue() === "true" ? "success" : "error"}
          sx={{ minWidth: 50 }}
        />
      ),
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchCustomers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customers && customers.length > 0) {
      const tableRows = customers?.map((user: ICustomer) => ({
        id: user.id,
        customerName: user.name,
        primaryNumber: user.primaryNumber,
        secondaryNumber: user.secondaryNumber,
        address: user.addresses[0]?.address,
        email: user.email,
        gender: user.gender,
        dateOfBirth: user.dob,
        isRecurring: user.isRecurring.toString(),
      }));
      setRows(tableRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialReactTable
            columns={columns}
            data={rows}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 100 },
            }}
            renderTopToolbarCustomActions={CustomersTableActionBar}
            enableRowActions
            renderRowActionMenuItems={CustomersTableActionMenu}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectCustomer = createSelector(selectSelf, (state) => state.customer);

export const selectIsCustomerLoading = createSelector(
  selectCustomer,
  (customer) => customer.isLoading
);

export const selectCustomers = createSelector(
  selectCustomer,
  (customer) => customer.customers
);

export const selectCustomerIds = createSelector(
  selectCustomer,
  (customer) => customer.customerIds
);

export const selectCustomerDetails = createSelector(
  selectCustomer,
  (customer) => customer.customerDetails
);

export const selectCustomerAddresses = createSelector(
  selectCustomer,
  (customer) => customer.customerAddresses
);

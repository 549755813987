import { Box, Grid, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CTypography from "src/components/CTypography";
import FileDropzone from "src/components/dropzone";
import FormikControl from "src/components/formik/FormikControl";
import { IComplaintForm, IComplaintFormProps } from "src/pages/customer/types";
import {
  selectComplaintCategories,
  selectSelectedComplaint,
} from "src/slices/complaints/selectors";
import {
  fetchComplaintCategories,
  fetchComplaintByComplaintId,
  uploadComplaintAttachments,
  fetchComplaints,
} from "src/slices/complaints/thunks";
import {
  IComplaint,
  IComplaintData,
  IComplaintPayload,
} from "src/slices/complaints/types";
import { selectCustomerIds } from "src/slices/customers/selectors";
import { fetchCustomerIds } from "src/slices/customers/thunks";
import { selectOrderIds } from "src/slices/orders/selectors";
import { fetchOrderIdsByCustomerId } from "src/slices/orders/thunks";
import { setDialog } from "src/slices/uiSettingsSlice";
import { AppDispatch } from "src/store";
import CloseIcon from "@mui/icons-material/Close";
import CModalConfirmation from "src/components/CModalConfirmation";

export const ComplaintForm: FC<IComplaintFormProps> = ({
  dialogName,
  customerId,
  complaintId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);

  const [files, setFiles] = useState([]);

  const [attachmentToRemove, setAttachmentToRemove] = useState<string[]>([]);
  const [selectedAttachment, setSelectedAttachment] =
    useState<IComplaintData>();

  const orderIds = useSelector(selectOrderIds);
  const complaintCategories = useSelector(selectComplaintCategories);
  const selectedComplaint: IComplaint = useSelector(selectSelectedComplaint);
  const selectedCustomerIds: any[] = useSelector(selectCustomerIds);

  useEffect(() => {
    dispatch(fetchCustomerIds());
    dispatch(fetchComplaintCategories());
    if (complaintId) {
      dispatch(fetchComplaintByComplaintId(complaintId));
    }
    if (customerId) {
      dispatch(fetchOrderIdsByCustomerId(customerId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (complaintId && selectedComplaint) {
      formikRef.current.setFieldValue("order", selectedComplaint.orderId);
      formikRef.current.setFieldValue("category", selectedComplaint.categoryId);
      formikRef.current.setFieldValue("detail", selectedComplaint.description);
      formikRef.current.setFieldValue(
        "isResolved",
        selectedComplaint.isResolved
      );
    }
    // eslint-disable-next-line
  }, [complaintId, selectedComplaint]);

  useEffect(()=>{
    if(selectedCustomerIds && customerId){
      const selectedCustomer = selectedCustomerIds.filter((customer) => customer.customerId === customerId);
      if(selectedCustomer.length > 0){
        formikRef.current.setFieldValue("customer", selectedCustomer[0].customerName);
      }
    }
  },[selectedCustomerIds, customerId]);

  // Form Inital Values
  const formInitialValue: IComplaintForm = {
    order: "",
    customer: "",
    category: "",
    detail: "",
    isResolved: false,
  };

  const onClickSubmit = async (values: IComplaintForm) => {
    const selectedCustomer = selectedCustomerIds.filter((customer) => customer.customerName === values.customer);
    let customerId = '';
    if(selectedCustomer.length > 0){
      customerId = selectedCustomer[0]?.customerId;
    }
    const complaintData: IComplaint = {
      complaintId: complaintId ? complaintId : null,
      orderId: values.order,
      customerId: customerId,
      description: values.detail,
      categoryId: values.category,
      isResolved: complaintId ? values.isResolved : false,
      attachments:
        complaintId && selectedComplaint
          ? selectedComplaint.attachments
              .filter(
                (attachment) => !attachmentToRemove.includes(attachment.fileUrl)
              )
              .map((attachment) => ({
                fileName: attachment.fileName,
                fileType: attachment.fileType,
                fileUrl: attachment.fileUrl,
              }))
          : [],
    };
    const complaintPayload: IComplaintPayload = {
      files,
      complaintData: complaintData,
    };
    dispatch(uploadComplaintAttachments(complaintPayload)).then((response) => {
      if (response.payload && !response.payload?.error) {
        dispatch(fetchComplaints());
        dispatch(setDialog({ open: false, dialogName }));
      }
    });
  };

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={formInitialValue}
            innerRef={formikRef}
            onSubmit={(values) => {
              onClickSubmit(values);
            }}
          >
            {({ touched, errors, setFieldValue, getFieldProps }) => (
              <Form id={dialogName}>
                {/* Complaint info */}
                <Grid container spacing={1} pb={3}>
                  <Grid item xs={12}>
                    <CTypography fontWeight="bold">
                      Complaint Information
                    </CTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="VirtualizedSearchableSelect"
                      required
                      name="customer"
                      label={"Customer"}
                      options={selectedCustomerIds.map(customer => customer.customerName)}
                      handleChange={(value) => {
                        setFieldValue("order", "");
                        setFieldValue("customer", value);
                        const selectedCustomer = selectedCustomerIds.filter((customer) => customer.customerName === value);
                        let customerId = '';
                        if(selectedCustomer.length > 0){
                          customerId = selectedCustomer[0]?.customerId;
                        }
                        dispatch(fetchOrderIdsByCustomerId(customerId));
                      }}
                      error={Boolean(touched.customer && errors.customer)}
                      helperText={touched.customer && errors.customer}
                      {...getFieldProps("customer")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="SelectField"
                      required
                      name="order"
                      label={"Order"}
                      options={orderIds?.map((orderId) => ({
                        value: orderId.id,
                        label: `LX-${orderId.id}`,
                      }))}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      {...getFieldProps("order")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="SelectField"
                      required
                      name="category"
                      label={"Category"}
                      options={complaintCategories?.map((categoryData) => ({
                        value: categoryData.id,
                        label: categoryData.category,
                      }))}
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                      {...getFieldProps("category")}
                    />
                  </Grid>
                  {complaintId && (
                    <Grid item xs={12} sm={6}>
                      <FormikControl
                        control="SelectField"
                        name="isResolved"
                        label="Resolved"
                        options={[
                          {
                            label: "Resolved",
                            value: true,
                          },
                          {
                            label: "Not Resolved",
                            value: false,
                          },
                        ]}
                        error={Boolean(touched.isResolved && errors.isResolved)}
                        helperText={touched.isResolved && errors.isResolved}
                        {...getFieldProps("isResolved")}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormikControl
                      control="Textarea"
                      multiline
                      rows={5}
                      required
                      name="detail"
                      label="Detail"
                      error={Boolean(touched.detail && errors.detail)}
                      helperText={touched.detail && errors.detail}
                      {...getFieldProps("detail")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CTypography fontWeight="bold">Attachments</CTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="right" alignItems="center">
                      {selectedComplaint?.attachments &&
                        selectedComplaint.attachments.map((attachment) => {
                          if (attachmentToRemove.includes(attachment.fileUrl))
                            return <></>;

                          return (
                            <>
                              <Grid item xs={10}>
                                <CTypography>{attachment.fileName}</CTypography>
                              </Grid>
                              <Grid item xs={2} textAlign="right">
                                <IconButton
                                  onClick={() => {
                                    setSelectedAttachment(attachment);
                                    dispatch(
                                      setDialog({
                                        open: true,
                                        dialogName:
                                          "RemoveAttachmentConfirmation",
                                      })
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </>
                          );
                        })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FileDropzone
                      acceptedFileTypes={{
                        "image/jpg": [],
                        "image/jpeg": [],
                        "image/png": [],
                        "video/mp4": [],
                      }}
                      onSelectedFilesChange={(files) => {
                        setFiles(files);
                      }}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <CModalConfirmation
        dialogName="RemoveAttachmentConfirmation"
        title="Confirmation"
        handleSaveButtonText="Yes"
        handleCancelButtonText="No"
        handleSave={() =>
          setAttachmentToRemove((value) => [
            ...value,
            selectedAttachment.fileUrl,
          ])
        }
        handleCancel={() =>
          dispatch(
            setDialog({
              open: false,
              dialogName: "RemoveAttachmentConfirmation",
            })
          )
        }
        content={`Do you want to remove this ${selectedAttachment?.fileName} attachment?`}
      />
    </Box>
  );
};

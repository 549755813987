import { CTabView } from "src/components/CTabView";
import ItemPanel from "./item-panel";
import PriceMappingPanel from "./price-mapping-panel";
import ServicePanel from "./service-panel";
import ExpensePanel from "./expense-panel";

export function Configuration() {
  return (
    <>
      <CTabView
        data={[
          { label: "MANAGE SERVICES", component: <ServicePanel /> },
          { label: "MANAGE ITEMS", component: <ItemPanel /> },
          { label: "PRICE MAPPING", component: <PriceMappingPanel /> },
          { label: "MANAGE EXPENSE", component: <ExpensePanel /> },
        ]}
      />
    </>
  );
}

import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface InfoCardProps {
  title: string;
  value: any;
  icon?: any;
  iconBackgoroundColor?: string;
  barChartList?: Array<InfoCardBarChartProps>;
}

interface InfoCardBarChartProps {
  month: any;
  year: any;
  value?: any;
}

export const InfoCard: FC<InfoCardProps> = ({
  title,
  value,
  icon,
  iconBackgoroundColor,
  barChartList,
}) => {
  const [alignment, setAlignment] = useState("monthly");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const labels = useMemo(
    () => [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const [data, setData] = useState(() => ({
    labels,
    datasets: [
      {
        label: title,
        data: barChartList?.map((value) => value.value),
        backgroundColor: "#10b9b2",
        // barPercentage: 0.3, // Adjust this value to decrease the bar width
        // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
      },
    ],
  }));

  useEffect(() => {
    if (alignment === "monthly") {
      setData({
        labels,
        datasets: [
          {
            label: title,
            data: labels.map((_, index) => {
              let selectedValue = barChartList?.filter(
                  (value) =>
                    value.year ===
                    Math.max(...barChartList.map((value) => value.year))
                )?.filter((data) => data.month === index + 1);
              if (selectedValue?.length !== 0) {
                return selectedValue?.[0].value;
              }
              return 0;
            }),
            backgroundColor: "#10b9b2",
            // barPercentage: 0.3, // Adjust this value to decrease the bar width
            // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
          },
        ],
      });
    } else {
      let years = Array.from(new Set(barChartList.map((d) => d.year)));
      years = years.sort();
      const yearCounts = [];
      for (const year of years) {
        const filteredByYear = barChartList?.filter((d) => d.year === year);
        const sum = filteredByYear.reduce((total, val) => total + val.value, 0);
        yearCounts.push(sum);
      }
      setData({
        labels: years,
        datasets: [
          {
            label: title,
            data: yearCounts,
            backgroundColor: "#10b9b2",
            // barPercentage: 0.3, // Adjust this value to decrease the bar width
            // categoryPercentage: 0.6, // Adjust this value to decrease the bar width
          },
        ],
      });
    }
  }, [title, labels, barChartList, alignment]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: false,
        text: title,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        // ticks: {
        //   // forces step size to be 50 units
        //   stepSize: 50
        // }
      },
    },
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ alignItems: "center" }}>
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={true}>
                <Typography color="textPrimary" variant="h4">
                  {value}
                </Typography>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="monthly">Monthly</ToggleButton>
                  <ToggleButton value="yearly">Yearly</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Bar options={options} data={data} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { ContainedButton } from "../button-group";
import FormikControl from "../formik/FormikControl";
import { FC, useRef } from "react";


interface KeyValue {
    key: string;
    value: number | string;
}

interface KeyValueProps {
    title: string;
    data: Array<KeyValue>;
    onClick: any;
}



export const KeyValueCard: FC<KeyValueProps> = ({
    title,
    data,
    onClick
}) => {
  const formikRef = useRef(null);
  return (
    <TableContainer component={Paper}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            <Formik
              initialValues={{
                startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                endDate: new Date(Date.now()),
              }}
              innerRef={formikRef}
              onSubmit={() => {}}
            >
              {({ values, touched, errors, getFieldProps }) => (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikControl
                      control="CalendarTime"
                      label="Start Date"
                      dateOrTimeOnly="date"
                      name="startDate"
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      {...getFieldProps("startDate")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikControl
                      control="CalendarTime"
                      label="End Date"
                      dateOrTimeOnly="date"
                      name="endDate"
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      {...getFieldProps("endDate")}
                    />
                  </Grid>
                  <Grid item>
                    <ContainedButton
                      onClick={() => {
                        onClick(values.startDate, values.endDate);
                      }}
                    >
                      Search
                    </ContainedButton>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontSize={18} fontWeight={'bold'}>{title}</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((keyValue) => (
                <TableRow
                  key={keyValue.key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{fontSize: 17}}>
                    {keyValue.key}
                  </TableCell>
                  <TableCell align="right" sx={{fontSize: 17}}>{keyValue.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

import { createSlice } from "@reduxjs/toolkit";

import {
  fetchOrdersByCustomerId,
  fetchOrderIdsByCustomerId,
  fetchOrderCountByCustomerId,
  fetchOrders,
  fetchOrderDetailsById,
  createUpdateOrder,
  fetchFilteredOrders,
  fetchOrdersPagination,
} from "./thunks";
import { IOrderSlice } from "./types";

const initialState: IOrderSlice = {
  orders: [],
  orderIds: [],
  selectedOrderDetails: null,
  selectedCustomerOrders: [],
  filteredOrders: [],
  isLoading: false,
  isError: false,
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    emptyOrderDetailsState: (state) => {
      state.selectedOrderDetails = null;
    },
  },
  extraReducers: (builder) => {
    // fetchComplaints
    builder.addCase(fetchOrderIdsByCustomerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrderIdsByCustomerId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orderIds = action.payload;
    });
    builder.addCase(fetchOrderIdsByCustomerId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchOrdersByCustomerId
    builder.addCase(fetchOrdersByCustomerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrdersByCustomerId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCustomerOrders = action.payload;
    });
    builder.addCase(fetchOrdersByCustomerId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //fetchOrderCountByCustomerId
    builder.addCase(fetchOrderCountByCustomerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrderCountByCustomerId.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrderCountByCustomerId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchOrders
    builder.addCase(fetchOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
    });
    builder.addCase(fetchOrders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchOrdersPagination
    builder.addCase(fetchOrdersPagination.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrdersPagination.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
    });
    builder.addCase(fetchOrdersPagination.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //fetchOrderDetailsById
    builder.addCase(fetchOrderDetailsById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrderDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedOrderDetails = action.payload;
    });
    builder.addCase(fetchOrderDetailsById.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //createUpdateOrder
    builder.addCase(createUpdateOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUpdateOrder.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createUpdateOrder.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchFilteredOrders
    builder.addCase(fetchFilteredOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFilteredOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filteredOrders = action.payload;
    });
    builder.addCase(fetchFilteredOrders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { emptyOrderDetailsState } = orderSlice.actions;
export const { reducer } = orderSlice;

import { Grid, Tooltip, IconButton } from "@mui/material";
import CTypography from "src/components/CTypography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const GoogleLocationCell = ({ cell }) => (
  <>
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <CTypography fontSize={14} sx={{ textOverflow: "ellipsis" }}>
          {cell.getValue()}{" "}
        </CTypography>
      </Grid>
      {!["", null, undefined].includes(cell.getValue()) && (
        <Grid item>
          <Tooltip title="Copy">
            <IconButton
              color="inherit"
              onClick={() =>
                navigator.clipboard.writeText(cell.getValue().toString())
              }
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  </>
);

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { IDashboardSlice } from "./types";

const selectSelf = (state: RootState) => state;
const selecDashboard = createSelector(selectSelf, (state) => state.dashboard);

export const selectIsDashboardLoading = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => dashboard.isLoading
);

export const selectDashboardData = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => ({
    customer: dashboard.customers,
    order: dashboard.orders,
    complaint: dashboard.complaints,
    customerCount: dashboard.customers?.length,
    orderCount: dashboard.orders?.length,
    complaintCount: dashboard.complaints?.length,
  })
);

export const selectTotalOrders = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => dashboard.totalOrders
);

export const selectTotalComplaints = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => dashboard.totalComplaints
);

export const selectTotalCustomers = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => dashboard.totalCustomers
);

export const selectTotalOrdersByStatus = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => dashboard.totalOrdersByStatus
);

export const selectTotalOrdersByServices = createSelector(
  selecDashboard,
  (dashboard: IDashboardSlice) => dashboard.totalOrdersByServices
);

import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { FC } from "react";
import { ICustomerOrderedService } from "src/slices/orders/types";
import { CustomerOrderedServiceGridProps } from "./types";

export const CustomerOrderedServicesGrid: FC<
  CustomerOrderedServiceGridProps
> = ({ rows }) => {
  const columns: MRT_ColumnDef<ICustomerOrderedService>[] = [
    {
      accessorKey: "service",
      header: "Service",
    },
    {
      accessorKey: "item",
      header: "Item",
    },
    {
      accessorKey: "estimatedNumOfItems",
      header: "Estimated Items",
    },
    {
      accessorKey: "price",
      header: "Price",
    },
  ];
  return (
    <MaterialReactTable
      columns={columns}
      data={rows.map((customerOrderedService) => {
        let price: number | string =
          parseInt(customerOrderedService?.price?.toString()) *
          parseInt(customerOrderedService.estimatedNumOfItems);
        price = isNaN(price) ? null : price.toString();
        return {
          ...customerOrderedService,
          price: price,
        };
      })}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
      muiTableHeadCellProps={{
        //no useTheme hook needed, just use the `sx` prop with the theme callback
        sx: (theme) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      }}
    />
  );
};
